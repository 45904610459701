import { React, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FieldAutocompleteOption from '../common/FieldAutocompleteOption';
import { toTitleCase } from "../../utils/stringFormatter";
import {
  Grid,
  Autocomplete,
  Dialog,
  DialogActions,
  ListItemButton,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  TextField,
  Chip,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import {
  ContentCopy as CopyIcon,
  List as ListIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Add as AddIcon,
  RefreshOutlined as RefreshOutlinedIcon,
} from "@mui/icons-material";
import sendRequest from "../../utils/sendRequest";
import { useHistory } from "react-router-dom";
import OrdererableList from "../../utils/OrderableList";
const GridBreak = styled("div")(({ theme }) => ({ width: "100%" }));

function TemplateEditor({ setLoading, handleSnackbar }) {
  const [action, setAction] = useState("edit");
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [templates, setTemplates] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [currentProductTypes, setCurrentProductTypes] = useState([]);
  const [fields, setFields] = useState([]);
  const [currentFields, setCurrentFields] = useState([]);
  const [currentDeleteTemplate, setCurrentDeleteTemplate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  async function getTemplates() {
    try {
      setLoading(true);
      var response = await sendRequest("get_templates", null, null, "GET");

      Promise.all([
        setTemplates(response["templates"]),
        setProductTypes(response["product_types"]),
        setFields(response["fields"]),
        setLoading(false),
      ]);

      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }
  useEffect(() => {

    getTemplates();
  }, []);

  

  async function handleTemplateDelete() {
    try {
      setLoading(true);
      var response = await sendRequest(
        "delete_template",
        null,
        { template_name: currentDeleteTemplate },
        "GET"
      );
      Promise.all([
        handleSnackbar(response["message"], "success"),
        setLoading(false),
        getTemplates(),
        setCurrentDeleteTemplate(""),
        setAction("edit"),
      ]);
    } catch (error) {
      Promise.all([handleSnackbar(error.message, "error"), setLoading(false)]);
    }
  }
  async function handleTemplateSelect(event, value, reason) {
    if (reason === "clear") {
      setCurrentTemplate("");
    }
    if (reason === "selectOption") {
      setCurrentTemplate(value);

      try {
        setLoading(true);
        var response = await sendRequest(
          "get_template",
          null,
          { template_name: value },
          "GET"
        );

        Promise.all([
          setCurrentFields(response["fields"]),
          setCurrentProductTypes(response["product_types"]),
          setLoading(false),
        ]);
      } catch (error) {
        Promise.all([
          handleSnackbar(error.message, "error"),
          setLoading(false),
        ]);
      }
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      var response = await sendRequest(
        action === "edit" ? "update_template" : "create_template",
        {
          template_name: currentTemplate,
          template_data: {
            fields: currentFields,
            product_types: currentProductTypes,
          },
        },
        null,
        "POST"
      );

      if (action !== "edit") {
        getTemplates();
      }
      Promise.all([
        handleSnackbar(response["message"], "success"),
        setLoading(false),
      ]);
    } catch (error) {
      Promise.all([handleSnackbar(error.message, "error"), setLoading(false)]);
    }
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        justifyItems={"center"}
        alignContent={"center"}
        sx={{ paddingY: 2 }}
      >
        <Grid item md={8} xs={11}>
          {action === "edit" ? (
            <Autocomplete
              id="select-template"
              value={currentTemplate}
              options={templates}
              fullWidth={true}
              renderInput={(params) => (
                <TextField {...params} label="Template" />
              )}
              onChange={(event, value, reason) =>
                handleTemplateSelect(event, value, reason)
              }
            />
          ) : (
            <TextField
              id="template-name"
              label="Template Name"
              fullWidth={true}
              size="small"
              value={currentTemplate}
              onChange={(event) => setCurrentTemplate(event.target.value)}
            />
          )}
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              Promise.all([
                setCurrentFields([]),
                setCurrentProductTypes([]),
                setCurrentTemplate(""),
                setAction("edit"),
              ]);
            }}
          >
            <ListIcon />
          </IconButton>
        </Grid>
        {action === "edit" && (
          <Grid item>
            <IconButton
              disabled={currentTemplate ? false : true}
              onClick={() => {
                Promise.all([
                  setCurrentTemplate(""),
                  setCurrentProductTypes([]),
                  setAction("add"),
                ]);
              }}
            >
              <CopyIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              handleSubmit();
            }}
            disabled={
              !currentProductTypes.length ||
              !currentFields.length ||
              !currentTemplate
            }
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              action === "edit"
                ? Promise.all([
                    setAction("add"),
                    setCurrentFields([]),
                    setCurrentProductTypes([]),
                    setCurrentTemplate(""),
                  ])
                : Promise.all([
                    setAction("edit"),
                    setCurrentFields([]),
                    setCurrentProductTypes([]),
                    setCurrentTemplate(""),
                  ]);
            }}
          >
            {action === "edit" ? "add" : "edit"}
          </Button>
        </Grid>
        {currentTemplate && (
          <>
            <Grid item>
              <IconButton
                onClick={() => {
                  Promise.all([
                    setCurrentDeleteTemplate(currentTemplate),
                    setModalOpen(true),
                    setModalTitle("Confirm Action"),
                    setModalMessage(
                      `Are you sure you want to delete template ${currentTemplate}`
                    ),
                  ]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        )}
        <Grid item>
          <IconButton
            onClick={() => {
              getTemplates();
            }}
          >
            <RefreshOutlinedIcon />
          </IconButton>
        </Grid>
        <GridBreak />
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        {action === "edit" && !currentTemplate ? (
          <Grid item md={8} xs={11}>
            {templates &&
              templates.map((template) => (
                <>
                  <ListItem
                    key={template}
                    dense
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          Promise.all([
                            setCurrentDeleteTemplate(template),
                            setModalOpen(true),
                            setModalTitle("Confirm Action"),
                            setModalMessage(
                              `Are you sure you want to delete template ${template}`
                            ),
                          ]);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        handleTemplateSelect("click", template, "selectOption");
                      }}
                    >
                      <ListItemText primary={template} />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              ))}
          </Grid>
        ) : (
          <></>
        )}
        {currentTemplate || !(action === "edit") ? (
          <>
            <Grid item md={11} xs={11}>
              <Autocomplete
                id="select-product-type"
                multiple
                fullWidth={true}
                size="small"
                options={productTypes}
                value={currentProductTypes}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField size="small" {...params} label="Product Types" />
                )}
                onChange={(event, value) => setCurrentProductTypes(value)}
              />
            </Grid>

            <GridBreak />
            <Grid
              item
              container
              md={12}
              xs={12}
              spacing={2}
              alignContent={"center"}
              direction={"row"}
              justifyContent={"center"}
            >
              <Grid item md={11} xs={10}>
                <Autocomplete
                  id="select-fields"
                  multiple
                  fullWidth={true}
                  size="small"
                  options={fields}
                  getOptionLabel={(option) => option.input_export_type_id?((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || ""):""}
                  renderOption={(props, option) => (
                    <FieldAutocompleteOption key={option.id} props={props} option={option} />
                  )
                  }
                  value={currentFields}
                  isOptionEqualToValue={(option, value) =>
                    option["id"] === value["id"]
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        variant="outlined"
                        label={option.input_export_type_id?((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || ""):""}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Fields" />
                  )}
                  onChange={(event, value) => setCurrentFields(value)}
                />
              </Grid>
              <Grid item>
                <IconButton size="medium" href={"/edit_field"} target="_blank">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={8} xs={11}>
              <OrdererableList
                options={currentFields}
                setOptions={setCurrentFields}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Dialog open={modalOpen} onClose={(e) => setModalOpen(false)}>
        <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>{modalMessage}</DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              Promise.all([setModalOpen(false), handleTemplateDelete()]);
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={(e) => {
              Promise.all([setModalOpen(false), setCurrentDeleteTemplate("")]);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TemplateEditor;
