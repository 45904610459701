import { React, useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import FieldAutocompleteOption from '../common/FieldAutocompleteOption';
import { toTitleCase } from "../../utils/stringFormatter";
import {
  Grid,
  Autocomplete,
  TextField,
  InputAdornment,
  Checkbox,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Chip,
  IconButton,
  Box,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Search as SearchIcon,
  Add as AddIcon,
  RefreshOutlined as RefreshOutlinedIcon,
  ContactlessOutlined,
} from "@mui/icons-material";
import sendRequest from "../../utils/sendRequest";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DataGrid, GridCellEditStopReasons,GridCellModes ,useGridApiRef } from "@mui/x-data-grid";
import OrdererableList from "../../utils/OrderableList";
import CustomGridToolbar from "./CustomGridToolbar";

const GridBreak = styled("div")(({ theme }) => ({ width: "100%" }));
const addNewRow = (field_id = "", parent_value = "", value = "") => {
  return { id: uuidv4(), field_id, parent_value, value };
};

function FieldEditor({ setLoading, handleSnackbar }) {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [showEbayMapper, setShowEbayMapper] = useState(false);
  const [ebayOptions, setEbayOptions] = useState([]);
  const [directFields, setDirectFields] = useState([]);
  const [ebayOption, setEbayOption] = useState("");
  const [selectOption, setSelectOption] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [currentField, setCurrentField] = useState("");
  const [fields, setFields] = useState([]);
  const [mappedFields, setMappedFields] = useState([]);
  const [inputTypes, setInputTypes] = useState([
    "text",
    "number",
    "boolean",
    "select",
    "select_free_text"
  ]);
  const [customImportOptions, setCustomImportOptions] = useState({
    ebay: "Ebay Specifics",
    sellercloud: "Sellercloud",
    sellercloud_custom: "Sellercloud Custom",
  });
  const [fieldData, setFieldData] = useState("");
  const [showRange, setShowRange] = useState(false);
  const [rangeMin, setRangeMin] = useState("");
  const [rangeMax, setRangeMax] = useState("");
  const [showCustomImport, setShowCustomImport] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [currentProductTypes, setCurrentProductTypes] = useState([]);
  const [currentFields, setCurrentFields] = useState([]);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("644625213089");
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [directMappedFieldsInputValue, setDirectMappedFieldsInputValue] = useState("");
  const [mappedFieldsInputValue, setMappedFieldsInputValue] = useState("");

  const [cellModesModel, setCellModesModel] = useState({});

  const apiRef = useGridApiRef();

  const handleCellClick = useCallback((params) => {
    if (params.field === 'parent_value' || params.field === 'value') {
      setCellModesModel((prevModel) => ({
        ...prevModel,
        [params.id]: {
          ...prevModel[params.id],
          [params.field]: { mode: GridCellModes.Edit },
        },
      }));
    }
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleRowClick = (rowParams) => {
    setSelectedRow(rowParams.id);
  };

  const handleRemoveRow = () => {
    if (selectedRow !== null) {
      setRows(rows.filter((row) => row.id !== selectedRow));
      setSelectedRow(null);
    }
  };

  const columns = [
    {
      field: "field_id",
      headerName: "Field ID",
      flex: 1.5,
      editable: true,
      renderCell: (params) => (
        <Autocomplete
          margin="none"
          fullWidth={true}
          options={ebayOptions}
          getOptionLabel={(option) => {
            const field = fields.find(f => f.id === option);
            return field && field.input_export_type_id
              ? `${toTitleCase(field.input_export_type_id)} - ${field.input_name}`
              : option || "";
          }}
          value={params.value}
          onChange={(event, newValue) => {
            handleCellEditCommit({
              id: params.id,
              field: params.field,
              value: newValue ,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="none"
              sx={{
                width: "100%",
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderWidth: 0,
                  },
                },
              }}
              variant="outlined"
            />
          )}
        />
      ),
    },
    { field: "parent_value", flex: 1, editable: true, headerName: "Parent Value",renderCell: (params) => (
      <TextField
        fullWidth
        value={params.value}
        onChange={(event) => {
          handleCellEditCommit({
            id: params.id,
            field: params.field,
            value: event.target.value,
          });
        }}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
      />
    ),},
    { field: "value", flex: 1, editable: true, headerName: "Value"},
  ];

  const handleCellEditCommit = useCallback(
    (params) => {
      const updatedRows = rows.map((row) =>
        row.id === params.id ? { ...row, [params.field]: params.value } : row
      );
      setRows(updatedRows);
    },
    [rows]
  );
  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, addNewRow()]);
  };
  async function getFields() {
    try {
      setLoading(true);
      var response = await sendRequest("fields/list", null, null, "GET");

      setFields(response.fields);
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }
  useEffect(() => {
    
    getFields();
  }, []);

  useEffect(() => {
    if (id) {
      handleFieldSelect(null, id, "selectOption");
    }
  }, [id,fields]);

  // const handleCellEditCommit = useCallback(
  //   ({ id, field, props }) => {
  //     const data = props;
  //     const updatedRows = rows.map((row) =>
  //       row.id === id ? { ...row, [field]: data.value } : row
  //     );
  //     setRows(updatedRows);
  //   },
  //   [rows]
  // );

  const handleCellEditStop = (params, event) => {
    let newValue;
    if (!event.target) {
      newValue = params.value;
    } else {
      newValue = event.target.value;
    }

    const id = params.id;
    const field = params.field;

    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: newValue };
      }
      return row;
    });

    setRows(updatedRows);
  };

  async function update_fields() {
    try {
      setLoading(true);
      var response = await sendRequest("update_fields", null, null, "GET");

      Promise.all([setFields(response)]);

      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function handleFieldSelect(event, value, reason) {
    if (reason === "clear") {
      setCurrentField("");
    }
    if (reason === "selectOption" || (typeof value === "string" && value.trim() !== "")) {
      let fieldId;
      if (typeof value === "string") {
        const fieldObject = fields.find(field => field.id === value);
        
        if (fieldObject) {
          setCurrentField(fieldObject);
          fieldId = fieldObject.id;
        } else {
          return;
        }
      } else {
        setCurrentField(value);
        fieldId = value.id;
      }

      try {
        setLoading(true);
        var response = await sendRequest(
          "get_field",
          null,
          { field_id: fieldId },
          "GET"
        );
        console.log(response,response["input_mappedfields"].length > 0 && response["input_isparent"])
        response["input_range"]
          ? Promise.all([
              setShowRange(true),
              setRangeMax(parseInt(response["input_range"].split("-")[1])),
              setRangeMin(parseInt(response["input_range"].split("-")[0])),
            ])
          : setShowRange(false);

        (response["input_mappedfields"].length > 0 && response["input_isparent"])
          ? Promise.all([
              setShowEbayMapper(true),
              setEbayOptions(response["input_mappedfields"]),
            ])
          : Promise.all([setShowEbayMapper(false), setEbayOptions([])]);

        response["input_directmappedfields"].length 
          ? Promise.all([
              //setShowEbayMapper(true),
              setDirectFields(fields.filter((field) => response["input_directmappedfields"].includes(field.id))),    
            ])
          : Promise.all([ setDirectFields([])]);

        response["input_fieldmap"].length
          ? Promise.all([
              //setShowEbayMapper(true),
              setRows(
                response["input_fieldmap"].map((row) => {
                  return addNewRow(row["field_id"], row["parent_value"], row["value"]);
                })
              ),
            ])
          : Promise.all([setShowEbayMapper(false), setRows([])]);

        response["input_export_type_id"].length
          ? setShowCustomImport(true)
          : setShowCustomImport(false);
        
        Promise.all([setFieldData(response), setLoading(false)]);
      } catch (error) {
        console.log(error);
        Promise.all([
          handleSnackbar(error.message, "error"),
          setLoading(false),
        ]);
      }
    }
  }

  async function handleSearch() {
    try {
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function handleAdd(
    selectOption,
    setSelectOption,
    selectOptions,
    setSelectOptions
  ) {
    if (selectOption) {
      const optionsArray = selectOption
        .split(",")
        .map((option) => option.trim());
      const uniqueOptions = [...new Set(optionsArray)].filter(
        (option) => !selectOptions.includes(option)
      );
      setSelectOptions([...selectOptions, ...uniqueOptions]);
      setSelectOption("");
    }
  }

  async function handleSubmit() {
    if (fieldData["input_name"].trim().length === 0) {
      setModalMessage("Field Name cannot be empty");
      setModalTitle("Error");
      setModalOpen(true);
      return;
    }
    if (fieldData["input_type"].trim().length === 0) {
      setModalMessage("Field Type cannot be empty");
      setModalTitle("Error");
      setModalOpen(true);
      return;
    }
    if (showRange && (!rangeMin || !rangeMax)) {
      setModalMessage("Range cannot be empty");
      setModalTitle("Error");
      setModalOpen(true);
      return;
    }
    if (showCustomImport && !fieldData["input_export_type_id"]) {
      setModalMessage("Custom Import cannot be empty");
      setModalTitle("Error");
      setModalOpen(true);
      return;
    }
    if (rangeMin > rangeMax) {
      setModalMessage("Range Min cannot be greater than Range Max");
      setModalTitle("Error");
      setModalOpen(true);
      return;
    }

    // if (showEbayMapper && !ebayOptions.length) {
    //   setModalMessage("Mapped Fields cannot be empty");
    //   setModalTitle("Error");
    //   setModalOpen(true);
    //   return;
    // }

    // if (ebayOptions.length && !rows.length) {
    //   setModalMessage("Ebay Map cannot be empty");
    //   setModalTitle("Error");
    //   setModalOpen(true);
    //   return;
    // }

    if (showEbayMapper && !ebayOptions.length && !directFields.length) {
      setModalMessage("Both direct and mapped fields cannot be empty");
      setModalTitle("Error");
      setModalOpen(true);
      return;
    }
    if (fieldData["input_type"] === "list") {
      fieldData["input_type"] = "select";
    }
    fieldData["input_range"] = showRange ? [rangeMin, rangeMax].join("-") : "";
    fieldData["input_options"] = showSelect ? selectOptions : [];
    fieldData["input_mappedfields"] = showEbayMapper ? ebayOptions : [];
    fieldData["input_directmappedfields"] = directFields ? directFields.map((field) => field.id) : [];
    var tableDataObject = showEbayMapper? Array.from(apiRef.current.getRowModels().values()):[]


    fieldData["input_fieldmap"] = showEbayMapper ? tableDataObject : [];
    
    try {
      setLoading(true);
      var response = await sendRequest(
        "update_field",
        { field_id: currentField.id, field_data: fieldData },
        null,
        "POST"
      );

      Promise.all([
        handleSnackbar(response["message"], "success"),
        setLoading(false),
      ]);
    } catch (error) {
      setModalMessage(error.message);
      setModalTitle("Error");
      setModalOpen(true);
      setLoading(false);
    }
  }
  function toTitleCase(str) {
    return str.replace(/_/g, ' ').replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ paddingY: 2 }}
      >
        { fields &&
         (<Grid item md={8} xs={11}>
          <Autocomplete
            id="select-field"
            autoFocus={true}
            options={fields}
            fullWidth={true}
            value={currentField}
            getOptionLabel={(option) => option.input_export_type_id?((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || ""):""}
            renderInput={(params) => <TextField {...params} label="Field" />}
            onChange={(event, value, reason) =>
              handleFieldSelect(event, value, reason)
            }
            renderOption={(props, option) => (
              <FieldAutocompleteOption key={option.id} props={props} option={option} />
            )}
          />
        </Grid>)
    }
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              handleSubmit();
            }}
            disabled={false}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              update_fields();
            }}
          >
            Refresh
          </Button>
        </Grid>
        <GridBreak />
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>

        {currentField && fieldData && (
          <>
            <Grid
              container
              item
              md={12}
              xs={12}
              alignItems={"center"}
              alignContent={"center"}
              justifyContent={"center"}
              justifyItems={"center"}
            >
              <FormGroup row={true}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        setFieldData({
                          ...fieldData,
                          input_required: event.target.checked,
                        });
                      }}
                      checked={fieldData["input_required"]}
                    />
                  }
                  label="Required"
                />

                {fieldData["input_isparent"] &&(
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          setShowEbayMapper(event.target.checked);
                        }}
                        checked={showEbayMapper}
                      />
                    }
                    label="Map Fields"
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        setFieldData({
                          ...fieldData,
                          input_skuspecific: event.target.checked,
                        });
                      }}
                      checked={fieldData["input_skuspecific"]}
                    />
                  }
                  label="SKU Specific"
                />
              </FormGroup>
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={4} xs={11}>
              <TextField
                id="input-input_name"
                variant="outlined"
                label="Field Name *"
                fullWidth={true}
                value={fieldData["input_name"]}
                onChange={(event) => {
                  setFieldData({
                    ...fieldData,
                    input_name: event.target.value,
                  });
                }}
              />
            </Grid>
            <GridBreak />
            <Grid item md={4} xs={11}>
              <FormControl fullWidth={true} variant="outlined">
                <InputLabel id="input-input_export_type_id">
                  Import Type *
                </InputLabel>
                <Select
                  id="input-input_export_type_id"
                  value={fieldData["input_export_type_id"]}
                  renderValue={(value) => customImportOptions[value]}
                  onChange={(event) => {
                    setFieldData({
                      ...fieldData,
                      input_export_type_id: event.target.value,
                    });
                  }}
                  label="Import Type *"
                >
                  {Object.keys(customImportOptions).map((option) => (
                    <MenuItem key={option} value={option}>
                      {customImportOptions[option]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <GridBreak />
            <Grid item md={4} xs={11}>
              <FormControl fullWidth={true} variant="outlined">
                <InputLabel id="input-input_type">Input Type *</InputLabel>
                <Select
                  id="input-input_type"
                  value={fieldData["input_type"]}
                  onChange={(event) => {
                    setFieldData({
                      ...fieldData,
                      input_type: event.target.value,
                    });
                    event.target.value === "list"
                      ? setShowSelect(true)
                      : setShowSelect(false);
                  }}
                  label="Input Type *"
                >
                  {inputTypes.map((option) => (
                    <MenuItem key={option} value={option}>
                      {toTitleCase(option.replace("select", "list"))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <GridBreak />
            {["text", "number"].includes(fieldData["input_type"]) && (
              <>
                <Grid item md={4} xs={11}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          onChange={(event) => {
                            setShowRange(event.target.checked);
                          }}
                          checked={showRange}
                        />
                      }
                      label="Range"
                    />
                  </FormControl>
                </Grid>
                <GridBreak />
              </>
            )}

            {showRange &&
              ["text", "number"].includes(fieldData["input_type"]) && (
                <>
                  <Grid item md={2} xs={5.5}>
                    <TextField
                      id="input-range_min"
                      type="number"
                      variant="outlined"
                      label="Min *"
                      fullWidth={true}
                      value={rangeMin}
                      onChange={(event) => {
                        setRangeMin(event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item md={2} xs={5.5}>
                    <TextField
                      id="input-range_max"
                      type="number"
                      variant="outlined"
                      label="Max *"
                      fullWidth={true}
                      value={rangeMax}
                      onChange={(event) => {
                        setRangeMax(event.target.value);
                      }}
                    />
                  </Grid>
                  <GridBreak />
                </>
              )}

            <GridBreak />
            <Grid item md={4} xs={11}>
                  <Autocomplete
                    id="input-direct_fields"
                    options={fields}
                    fullWidth={true}
                    inputValue={directMappedFieldsInputValue}
                    onInputChange={(event, newInputValue) => {
                      setDirectMappedFieldsInputValue(newInputValue);
                    }}
                    getOptionLabel={(option) => option.input_export_type_id?((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || ""):""}
                    renderOption={(props, option) => (
                      <FieldAutocompleteOption key={option.id} props={props} option={option} />

                    )}
                    onChange={(event, value, reason) => {
                      if (reason === "selectOption") {
                        setDirectFields([...directFields, value]);
                        setDirectMappedFieldsInputValue("");
                      }
                      if (reason === "removeOption") {
                        setDirectFields(
                          directFields.filter((item) => item !== value)
                        );
                      }
                    }}
                    value={null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="input-direct_fields_options"
                        variant="outlined"
                        label="Direct Fields"
                      />
                    )}
                  />
                </Grid>
                <GridBreak />
                <Grid container spacing={1} item md={4} xs={11}>
                  {directFields.map((option) => (
                    <Chip
                      key={option.id}
                      label={option.input_export_type_id?((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || ""):""}
                      size="small"
                      onDelete={(e) => {
                        setDirectFields(
                          directFields.filter((item) => item !== option)
                        );
                      }}
                      color="primary"
                      variant="outlined"
                      sx={{ margin: 0.5 }}
                    />
                  ))}
                </Grid>
            {showEbayMapper && (
              <>
                <GridBreak />
                <Grid item md={4} xs={11}>
                  <Autocomplete
                    id="input-ebay_mapper"
                    options={fields}
                    fullWidth={true}
                    inputValue={mappedFieldsInputValue}
                    onInputChange={(event, newInputValue) => {
                      setMappedFieldsInputValue(newInputValue);
                    }
                    }
                    getOptionLabel={(option) => option.input_export_type_id?((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || ""):""}
                    renderOption={(props, option) => (
                      <FieldAutocompleteOption key={option.id} props={props} option={option} />

                    )}
                    onChange={(event, value, reason) => {
                      if (reason === "selectOption") {
                        setEbayOptions([...ebayOptions, value.id]);
                        setMappedFieldsInputValue("");
                      }
                      if (reason === "removeOption") {
                        setEbayOptions(
                          ebayOptions.filter((item) => item !== value)
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="input-ebay_options"
                        variant="outlined"
                        label="Mapped Fields"
                      />
                    )}
                  />
                </Grid>
                <GridBreak />
                <Grid container spacing={1} item md={4} xs={11}>
                {ebayOptions.map((option) => {
                  const field = fields.find(f => f.id === option);
                  const label = field && field.input_export_type_id
                    ? `${toTitleCase(field.input_export_type_id)} - ${field.input_name}`
                    : option || "";

                  return (
                    <Chip
                      key={option}
                      label={label}
                      size="small"
                      onDelete={() => {
                        setEbayOptions(
                          ebayOptions.filter((item) => item !== option)
                        );
                      }}
                      color="primary"
                      variant="outlined"
                      sx={{ margin: 0.5 }}
                    />
                  );
                })}
                </Grid>
              </>
            )}
          </>
        )}

        <GridBreak />
        {showEbayMapper && (
          <Grid item md={8} xs={11}>
            <div style={{ height: 350, width: "100%" }}>
              <DataGrid
                apiRef={apiRef}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
                onCellEditStop={(params, event) => {
                  handleCellEditCommit(params);
                }}
                onRowClick={handleRowClick}
                density="compact"
                rows={rows}
                columns={columns}
                initialState={{
                  columns: {
                    columnVisibilityModel: {},
                  },
                }}
                slots={{
                  toolbar: () => (
                    <CustomGridToolbar
                      handleAddRow={handleAddRow}
                      handleRemoveRow={handleRemoveRow}
                    />
                  ),
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </div>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={modalOpen}
        onClose={(e) => setModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>{modalMessage}</DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              Promise.all([setModalOpen(false)]);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FieldEditor;
