import { API_ENDPOINT, LOGIN_ENDPOINT } from "../constants";

import axios from 'axios';
import qs from 'qs';


const axiosInstance = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,
});
let interceptorsSet = false;
export const setUpInterceptors = (enqueueSnackbar, setLoading, history, setAuthenticated) => {

  if (interceptorsSet) return;
  interceptorsSet = true;

  axiosInstance.interceptors.request.use(
    (config) => {
      setLoading(true);
      return config;
    },
    (error) => {
      setLoading(false);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      setLoading(false);

      if (response.config.isLoginRequest) {
        setAuthenticated(true);
      }

      return response;
    },
    (error) => {
      setLoading(false);

      if (error.response && error.response.status === 401) {
        setAuthenticated(false);
        console.log(LOGIN_ENDPOINT + "?redirect_url=" + window.location.href);
        history.push(LOGIN_ENDPOINT + "?redirect_url=" + window.location.href);
      }

      const errorMessage = getErrorMessage(error);
      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }

      return Promise.reject(error);
    }
  );
};

const getErrorMessage = (error) => {
  if (error.config && error.config.url && error.config.url.includes("verify_session")) {
    return null;
  }
  if (error.response) {
    if (error.response.status === 422) {
      return 'Invalid request';
    }
    return error.response.data?.detail || 'An error occurred';
  }
  return error.message || 'An error occurred';
};

const sendRequest = async (url, data = null, params = null, method, isLoginRequest = false) => {
  try {
    const config = {
      method,
      url,
      isLoginRequest: isLoginRequest,
    };

    if (data) {
      config.data = data;
    }

    if (params) {
      config.params = params;
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      };
    }

    const response = await axiosInstance(config);

    return response.data;
  } catch (error) {
    if (error.config && error.config.url && error.config.url.includes("verify_session")) {
      return null;
    }
    console.log(error);
    if (error.response && error.response.status === 401) {
      console.log(LOGIN_ENDPOINT + "?redirect_url=" + window.location.href);
      window.location.href = LOGIN_ENDPOINT + "?redirect_url=" + window.location.href;
    }
    const enhancedError = new Error(getErrorMessage(error));

    enhancedError.response = error.response || null;
    enhancedError.request = error.request || null;
    enhancedError.config = error.config || null;

    throw enhancedError;
  }
};

export default sendRequest;