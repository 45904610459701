import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Typography, 
  Autocomplete, 
  TextField, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction, 
  IconButton, 
  Divider,
  Paper,
  Box
} from '@mui/material';
import FieldAutocompleteOption from '../common/FieldAutocompleteOption';
import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import sendRequest from '../../utils/sendRequest';

function toTitleCase(str) {
  return str.replace(/_/g, ' ').replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const ParentFieldsEditor = ({ setLoading, handleSnackbar }) => {
  const [fields, setFields] = useState({});
  const [selectedField, setSelectedField] = useState(null);
  const [allFields, setAllFields] = useState([]);

  useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      setLoading(true);
      const response = await sendRequest('field_mapping/list', null, null, 'GET');
      const groupedFields = response.fields.reduce((acc, field) => {
        if (!acc[field.input_export_type_id]) {
          acc[field.input_export_type_id] = [];
        }
        acc[field.input_export_type_id].push(field);
        return acc;
      }, {});
      setFields(groupedFields);
      setAllFields(response.fields);
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  const handleFieldSelect = async (event, value) => {
    if (value) {
      try {
        setLoading(true);
        const response = await sendRequest('field_mapping/' + value.id, null, null, 'GET');
        setSelectedField(response);
        setLoading(false);
      } catch (error) {
        handleSnackbar(error.message, 'error');
        setLoading(false);
      }
    } else {
      setSelectedField(null);
    }
  };

  const handleMakeParent = async (childId) => {
    try {
      setLoading(true);
      await sendRequest('field_mapping/make_parent', null, { child_id: childId, parent_id: selectedField.id }, 'POST');
      handleSnackbar('Field hierarchy updated successfully', 'success');
      
      // Fetch the updated child field data
      const updatedChildField = await sendRequest('field_mapping/' + childId, null, null, 'GET');
      
      // Update the selectedField with the child field data
      setSelectedField(updatedChildField);
      
      await fetchFields();
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  const renderFieldList = (fieldIds, title) => (
    <>
      <Typography variant="h6">{title}</Typography>
      <List>
        {fieldIds.map((fieldId) => {
          const field = allFields.find(f => f.id === fieldId);
          return (
            <React.Fragment key={fieldId}>
              <ListItem>
                <ListItemText 
                  primary={field ? field.input_name : fieldId}
                  secondary={field ? toTitleCase(field.input_export_type_id) : ''}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleMakeParent(fieldId)}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </>
  );

  return (
    <Grid container spacing={2} sx={{ padding: 2 }} alignItems={"center"}
    justifyContent={"center"}>

      <Grid item  md={8} xs={11}>
        <Autocomplete
          options={allFields.filter(field => !field.parent_id)}
          getOptionLabel={(option) => option.input_name}
          renderInput={(params) => <TextField {...params} label="Select Field" />}
          onChange={handleFieldSelect}
          value={selectedField}
          renderOption={(props, option) => (
            <FieldAutocompleteOption key={option.id} props={props} option={option} />
          )}
        />
      </Grid>
      {selectedField && (
        <Grid item xs={11} md={10}>
          <Paper elevation={3} sx={{ p: 2, mt: 2, mb: 2 }}>
            <Typography variant="h6">{selectedField.input_name}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {toTitleCase(selectedField.input_export_type_id)}
            </Typography>
          </Paper>
        </Grid>
      )}
      {selectedField && (
        <>
          <Grid item xs={12} md={5}>
            {renderFieldList(selectedField.input_mappedfields, "Mapped Fields")}
          </Grid>
          <Grid item xs={12} md={5}>
            {renderFieldList(selectedField.input_directmappedfields, "Direct Mapped Fields")}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ParentFieldsEditor;