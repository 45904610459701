import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Button, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
function CustomGridToolbar({ handleAddRow, handleRemoveRow }) {
  return (
    <GridToolbarContainer>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleAddRow}
            size="small"
          >
            Add Row
          </Button>
          <Button
            startIcon={<DeleteIcon fontSize="small" />}
            onClick={handleRemoveRow}
            size="small"
          >
            Remove Row
          </Button>

          <GridToolbarFilterButton />
          <GridToolbarExport />
        </Grid>
        <Grid item>
          <GridToolbarQuickFilter />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default CustomGridToolbar;
