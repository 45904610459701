import React from 'react';
import { Box, Typography } from '@mui/material';
import {toTitleCase} from '../../utils/stringFormatter';
const colorMap = {
  ebay: '#E06C75',  // Soft red (Atom's variable color)
  sellercloud: '#61AFEF',  // Soft blue (Atom's function color)
  sellercloud_custom: '#98C379',  // Soft green (Atom's string color)
  default: '#C678DD'  // Soft purple (Atom's keyword color)
};


const FieldAutocompleteOption = ({ props, option }) => {
  const exportType = option.input_export_type_id || 'default';
  const color = colorMap[exportType] || colorMap.default;

  return (
    <li {...props}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography variant="body2" sx={{ color: color,}}>
          {`${toTitleCase(exportType)} - ${option.input_name}`}
        </Typography>
      </Box>
    </li>
  );
};

export default FieldAutocompleteOption;