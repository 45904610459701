import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  Autocomplete,
} from '@mui/material';
import sendRequest from '../../utils/sendRequest';

const AddSellercloudFields = ({ setLoading, handleSnackbar }) => {
  const [fieldData, setFieldData] = useState({
    id: '',
    input_name: '',
    input_type: '',
    input_options: [],
    input_required: false,
    input_validations: '',
    input_range: '',
    input_export_type_id: '',
    input_ismultiselect: false,
    input_isparent: false,
    input_skuspecific: false,
  });

  const [sellercloudFields, setSellercloudFields] = useState({ sellercloud: [], sellercloud_custom: [] });
  const [availableFields, setAvailableFields] = useState([]);

  useEffect(() => {
    fetchSellercloudFields();
  }, []);

  const fetchSellercloudFields = async () => {
    try {
      setLoading(true);
      const response = await sendRequest('sellercloud_fields_meta', null, null, 'GET');
      setSellercloudFields(response);
      setAvailableFields([...response.sellercloud, ...response.sellercloud_custom]);
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, 'error');
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFieldData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      fieldData.input_directmappedfields = [];
      fieldData.input_mappedfields = [];
      const response = await sendRequest('add_sellercloud_field', {
        field_id: fieldData.id,
        field_data: fieldData
      }, null, 'POST');
      handleSnackbar(response.message, 'success');
      setLoading(false);
      // Reset form
      setFieldData({
        id: '',
        input_name: '',
        input_type: '',
        input_options: [],
        input_required: false,
        input_validations: '',
        input_range: '',
        input_export_type_id: '',
        input_ismultiselect: false,
        input_isparent: false,
        input_skuspecific: false,
      });
    } catch (error) {
      handleSnackbar(error.response?.data?.detail || error.message, 'error');
      setLoading(false);
    }
  };

  return (
    <Grid
        container
        spacing={2}
        sx={{ paddingTop: 3 }}
        alignItems={"center"}
        justifyContent={"center"}
      >
      <Grid item md={8} xs={11}>
      <Typography variant="h5" gutterBottom>Add Sellercloud Field</Typography>
      <Divider style={{ margin: '20px 0' }} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              options={availableFields}
              renderInput={(params) => <TextField {...params} label="Sellercloud Field ID" required />}
              onChange={(event, newValue) => {
                setFieldData(prevData => ({
                  ...prevData,
                  id: newValue,
                  input_export_type_id: sellercloudFields.sellercloud.includes(newValue) ? 'sellercloud' : 'sellercloud_custom'
                }));
              }}
              value={fieldData.id}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Input Name"
              name="input_name"
              value={fieldData.input_name}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Input Type</InputLabel>
              <Select
                name="input_type"
                value={fieldData.input_type}
                onChange={handleInputChange}
                required
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="select">Select</MenuItem>
                <MenuItem value="select_free_text">Select with Free Text</MenuItem>
                <MenuItem value="boolean">Boolean</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Export Type</InputLabel>
              <Select
                name="input_export_type_id"
                value={fieldData.input_export_type_id}
                onChange={handleInputChange}
                required
              >
                <MenuItem value="sellercloud">Sellercloud</MenuItem>
                <MenuItem value="sellercloud_custom">Sellercloud Custom</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {['select', 'select_free_text'].includes(fieldData.input_type) && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Input Options (comma-separated)"
                name="input_options"
                value={fieldData.input_options.join(', ')}
                onChange={(e) => setFieldData(prevData => ({
                  ...prevData,
                  input_options: e.target.value.split(',').map(option => option.trim())
                }))}
              />
            </Grid>
          )}
          {['text', 'number'].includes(fieldData.input_type) && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={fieldData.input_type === 'number' ? "Input Range (e.g., 1-100)" : "Input Length Range (e.g., 1-255)"}
                name="input_range"
                value={fieldData.input_range}
                onChange={handleInputChange}
                helperText={fieldData.input_type === 'number' ? "Specify the minimum and maximum values" : "Specify the minimum and maximum length"}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Input Validations (regex)"
              name="input_validations"
              value={fieldData.input_validations}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fieldData.input_required}
                  onChange={handleInputChange}
                  name="input_required"
                />
              }
              label="Required"
            />
          </Grid>
          {['select', 'select_free_text'].includes(fieldData.input_type) && (
            <Grid item >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fieldData.input_ismultiselect}
                    onChange={handleInputChange}
                    name="input_ismultiselect"
                  />
                }
                label="Multi-select"
              />
            </Grid>
          )}
          <Grid item >
            <FormControlLabel
              control={
                <Checkbox
                  checked={fieldData.input_isparent}
                  onChange={handleInputChange}
                  name="input_isparent"
                />
              }
              label="Is Parent"
            />
          </Grid>
          <Grid item >
            <FormControlLabel
              control={
                <Checkbox
                  checked={fieldData.input_skuspecific}
                  onChange={handleInputChange}
                  name="input_skuspecific"
                />
              }
              label="SKU Specific"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add Field
            </Button>
          </Grid>
        </Grid>
      </form>
      </Grid>
    </Grid>
  );
};

export default AddSellercloudFields;