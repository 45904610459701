import Grid from "@mui/material/Grid";
import {
  getTemplate,
  getUiOptions,
  canExpand,
  descriptionId,
  titleId,
} from "@rjsf/utils";

export default function CustomObjectFieldTemplate(props) {
  const {
    description,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
    registry,
  } = props;

  const uiOptions = getUiOptions(uiSchema);

  const TitleFieldTemplate = getTemplate(
    "TitleFieldTemplate",
    registry,
    uiOptions
  );

  const DescriptionFieldTemplate = getTemplate(
    "DescriptionFieldTemplate",
    registry,
    uiOptions
  );

  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;

  return (
    <>
      {title && (
        <TitleFieldTemplate
          id={titleId(idSchema)}
          title={title}
          required={required}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      {description && (
        <DescriptionFieldTemplate
          id={descriptionId(idSchema)}
          description={description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <Grid container={true} spacing={2} style={{ marginTop: "5px" }}>
        {properties.map((element, index) => {
          {console.log(element.name,uiSchema?.[element.name]?.["ui:fieldFlexWidth"])}
        })}
        
        {properties.map((element, index) =>
          element.hidden ? (
            element.content
          ) : (
            <Grid
              item={true}
              xs={uiSchema?.[element.name]?.["ui:fieldFlexWidth"] ?? 4}
              key={index}
              style={{ marginBottom: "0px" }}
            >
              {element.content}
            </Grid>
          )
        )}
        {canExpand(schema, uiSchema, formData) && (
          <Grid container justifyContent="flex-end">
            <Grid item={true}>
              <AddButton
                className="object-property-expand"
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
                uiSchema={uiSchema}
                registry={registry}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
