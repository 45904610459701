import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import sendRequest from "../../utils/sendRequest";
const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem",
  border: "1px solid #ccc",
  borderRadius: "0.5rem",
});

const StyledTextField = styled(TextField)({
  marginBottom: "1rem",
});

const StyledButton = styled(Button)({
  marginTop: "1rem",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#3f51b5",
  "&:hover": {
    textDecoration: "underline",
  },
});

const Login = ({ handleSnackbar, setLoading }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const data = await sendRequest(
        "auth",
        { username: username, password: password },
        null,
        "POST"
      );
      localStorage.setItem("token", data.token);
      history.push("/");
      Promise.all([
        handleSnackbar("Login successful", "success"),
        setLoading(false),
      ]);
    } catch (error) {
      Promise.all([handleSnackbar(error.message, "error"), setLoading(false)]);
    }
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <StyledTextField
          label="Username"
          size="small"
          value={username}
          multiline={false}
          fullWidth={true}
          onChange={(e) => setUsername(e.target.value)}
        />
        <StyledTextField
          label="Password"
          type={showPassword ? "text" : "password"}
          multiline={false}
          size="small"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") handleLogin();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <StyledButton variant="contained" onClick={handleLogin}>
          Login
        </StyledButton>
      </StyledBox>
    </StyledContainer>
  );
};

export default Login;
