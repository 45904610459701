import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { List, ListItem,Typography,FormControlLabel, ListItemText, ListItemSecondaryAction, IconButton, Checkbox, Divider } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { toTitleCase } from "./stringFormatter";
const OrdererableList = ({ options, setOptions }) => {

    const handleDragEnd = (result) => {
        if (!result.destination) return;
    
        const newOptions = Array.from(options);
        const [reorderedItem] = newOptions.splice(result.source.index, 1);
        newOptions.splice(result.destination.index, 0, reorderedItem);
    
        setOptions(newOptions);
    };
    
    const handleDeleteItem = (index) => {
        const newOptions = Array.from(options);
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };

    const handleToggleRequired = (index) => {
        const newOptions = Array.from(options);
        newOptions[index].input_required = !newOptions[index].input_required;
        setOptions(newOptions);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="ordered-list">
                {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef} dense={true}>
                        {options.map((option, index) => (
                            <React.Fragment key={option.id}>
                                <Draggable draggableId={option.id.toString()} index={index}>
                                    {(provided) => (
                                        <ListItem
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            sx={{ border: '1px solid #ccc', borderRadius: '4px', mb: 1 }}
                                            secondaryAction={
                                                <>
                                                <Typography variant="caption">{"Required"}</Typography>
                                                <Checkbox
                                                aria-label='required'
                                                checked={option.input_required}
                                                onChange={() => handleToggleRequired(index)}
                                                />
                                                <IconButton edge="end" aria-label="delete" size="small" onClick={() => handleDeleteItem(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </>
                                            }
                                        >

                                            <ListItemText primary={option.input_export_type_id?((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || ""):""} />
                                        </ListItem>
                                    )}
                                </Draggable>
                            </React.Fragment>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};
    
export default OrdererableList;
