function snakeToTitle(snakeStr) {
  return snakeStr
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

function toTitleCase(str) {
  return str
    .replace(/_/g, " ")
    .replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
}

export { snakeToTitle, toTitleCase };
