import React, { useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Autocomplete,
  Link,
  InputBase,
  IconButton,
  Paper,
  TextField,
  FormHelperText,
  FormControl,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import formatDate from "../../utils/formatDate";

const GridBreak = styled("div")(({ theme }) => ({ width: "100%" }));

const ProductCard = React.memo(({ product }) => (
  <Card>
    <CardMedia
      component="img"
      height="240"
      image={product.ImageUrl || "https://lux.delta.sellercloud.com/assets/images/no-pic.svg"}
      alt={product.ProductName}
      style={{ objectFit: "contain" }}
    />
    <CardContent>
      <Link
        color="inherit"
        underline="none"
        href={`https://lux.delta.sellercloud.com/catalog/catalog-details.aspx?ID=${encodeURIComponent(product.ID)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography gutterBottom variant="h5" component="div">
          {product.ProductName}
        </Typography>
      </Link>
      <Typography gutterBottom variant="body1">
        {product.ProductType}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        SKU: {product.ID}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        MSKU: {product.ManufacturerSKU}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        UPC: {product.UPC}
      </Typography>
    </CardContent>
  </Card>
));

function SearchPage({
  setLoading,
  handleSnackbar,
  sendRequest,
  currentProduct,
  setCurrentProduct,
  selectedProductType,
  setSelectedProductType,
  setCurrentFormId,
}) {
  const [productTypes, setProductTypes] = useState([]);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [existingFormDialog, setExistingFormDialog] = useState(false);
  const [confirmDeleteFormDialog, setConfirmDeleteFormDialog] = useState(false);

  const getProductTypes = useCallback(async () => {
    try {
      setLoading(true);
      const response = await sendRequest("get_product_types", null, null, "GET");
      setProductTypes(response);
    } catch (error) {
      handleSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, [sendRequest, setLoading, handleSnackbar]);

  useEffect(() => {
    getProductTypes();
    setCurrentFormId("");
    setCurrentProduct(null);
    setSelectedProductType("");
  }, [getProductTypes, setCurrentFormId, setCurrentProduct, setSelectedProductType]);

  const handleSearch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await sendRequest(
        "search_products",
        { search_term: searchQuery },
        null,
        "POST"
      );
      setCurrentProduct(response);
      setCurrentFormId("");
      if (productTypes.includes(response.ProductType)) {
        setSelectedProductType(response.ProductType);
      }
      handleSnackbar("Product found", "success");
    } catch (error) {
      handleSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, [searchQuery, sendRequest, setLoading, handleSnackbar, productTypes, setCurrentProduct, setCurrentFormId, setSelectedProductType]);

  const closeExistingFormDialog = () => setExistingFormDialog(false);
  const closeConfirmDeleteFormDialog = () => {
    setExistingFormDialog(false);
    setConfirmDeleteFormDialog(false);
  };

  const handleOverwriteForm = () => {
    closeExistingFormDialog();
    setCurrentFormId(currentProduct.form_id);
    history.push("/add_details");
  };

  const handleNewForm = () => setConfirmDeleteFormDialog(true);

  const handleConfirmNewForm = async () => {
    try {
      setLoading(true);
      await sendRequest(
        "delete_submission",
        null,
        { form_id: currentProduct.form_id },
        "DELETE"
      );
      handleSnackbar("Submission deleted", "success");
      closeConfirmDeleteFormDialog();
      closeExistingFormDialog();
      setCurrentFormId("");
      history.push("/add_details");
    } catch (error) {
      handleSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  if (productTypes.length === 0) return null;

  return (
    <Grid
      container
      spacing={4}
      alignItems="center"
      justifyContent="center"
      sx={{ paddingY: 2 }}
      direction="row"
    >
      <Grid item md={8} xs={11}>
        <Paper elevation={3} sx={{ paddingLeft: 2, paddingRight: 1 }}>
          <InputBase
            autoFocus
            size="medium"
            value={searchQuery}
            fullWidth
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search products..."
            inputProps={{ "aria-label": "search product" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
                e.preventDefault();
              }
            }}
            endAdornment={
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            }
            sx={{ marginY: 0.5 }}
          />
        </Paper>
      </Grid>
      <GridBreak />
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        md={12}
        xs={12}
        spacing={6}
      >
        <Grid item md={3} xs={6}>
          <FormControl fullWidth size="small" margin="none">
            <Autocomplete
              id="select-product-type"
              value={selectedProductType}
              options={productTypes}
              renderInput={(params) => (
                <TextField {...params} label="Product Type" />
              )}
              onChange={(_, newValue) => setSelectedProductType(newValue)}
            />
            <FormHelperText>Please select a product type</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sx={{ paddingBottom: 3 }}>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              if (currentProduct?.form_id === null) {
                history.push("/add_details");
                setCurrentFormId("");
              } else {
                setExistingFormDialog(true);
              }
            }}
            disabled={!currentProduct || !selectedProductType}
          >
            Add Details
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={10} md={3}>
        {currentProduct && <ProductCard product={currentProduct} />}
      </Grid>
      <Dialog open={existingFormDialog} onClose={closeExistingFormDialog}>
        <DialogTitle>Overwrite existing form or create new one?</DialogTitle>
        {currentProduct && (
          <DialogContent>
            <DialogContentText>
              Modified by {currentProduct.modified_by} on{" "}
              {formatDate(currentProduct.modified_on)}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleOverwriteForm} color="primary">
            Open Draft
          </Button>
          <Button onClick={handleNewForm} color="primary">
            New
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDeleteFormDialog}
        onClose={closeConfirmDeleteFormDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The old form will be deleted. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDeleteFormDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmNewForm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default SearchPage;