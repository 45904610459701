import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Container,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import sendRequest from "../../utils/sendRequest";

export default function UserManagement({ handleSnackbar, setLoading }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [open, setOpen] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [password, setPassword] = useState("");

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "username", headerName: "Username", width: 200 },
    {
      field: "is_admin",
      headerName: "Admin Status",
      width: 200,
      renderCell: (params) => (
        <Select
          value={params.value ? true : false}
          onChange={(event) =>
            handleAdminStatusChange(params.row.id, event.target.value)
          }
          fullWidth={true}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      ),
    },
  ];
  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUsername("");
    setPassword("");
    setIsAdmin(false);
    setDialogOpen(false);
  };

  const handlePasswordDialogOpen = () => setOpenPasswordDialog(true);
  const handlePasswordDialogClose = () => {
    setOpenPasswordDialog(false);
    setPassword("");
  };

  const addUser = async () => {
    try {
      setLoading(true);
      const newUser = {
        id: users[users.length - 1]["id"] + 1,
        username,
        isAdmin,
        password,
      };
      const response = await sendRequest("add_user", newUser, null, "POST");
      setUsers([...users, response]);
      Promise.all([
        handleClose(),
        handleSnackbar("User added successfully", "success"),
        setLoading(false),
      ]);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
      handleClose();
    }
  };

  const removeUser = async () => {
    try {
      setLoading(true);
      await sendRequest(
        "delete_user",
        null,
        { user_id: selectedUser.id },
        "DELETE"
      );
      setUsers(users.filter((user) => user.id !== selectedUser.id));

      Promise.all([
        setLoading(false),
        setConfirmDialogOpen(false),
        setSelectedUser(null),
        handleSnackbar("User removed successfully", "success"),
      ]);
    } catch (error) {
      setConfirmDialogOpen(false);

      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  };

  const toggleAdminStatus = async () => {
    try {
      setLoading(true);
      const updatedUser = { ...selectedUser, is_admin: !selectedUser.is_admin };
      await sendRequest(`update_user`, updatedUser, null, "POST");
      setUsers(
        users.map((user) => (user.id === selectedUser.id ? updatedUser : user))
      );

      Promise.all([
        setLoading(false),
        setAdminDialogOpen(false),
        handleSnackbar("User admin status updated successfully", "success"),
      ]);
    } catch (error) {
      setAdminDialogOpen(false);
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  };
  const handleAdminStatusChange = async (userId, isAdmin) => {
    setSelectedUser(users.find((user) => user.id === userId));
    try {
      setAdminDialogOpen(true);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  };

  const updatePassword = async () => {
    try {
      setLoading(true);
      await sendRequest(
        `/users/${selectedUser.id}/password`,
        { password },
        null,
        "PUT"
      );
      handlePasswordDialogClose();
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  };
  React.useEffect(() => {
    async function getUsers() {
      try {
        setLoading(true);
        var response = await sendRequest("app_users", null, null, "GET");

        Promise.all([setUsers(response), setLoading(false)]);
      } catch (error) {
        handleSnackbar(error.message, "error");
        setLoading(false);
      }
    }
    getUsers();
  }, []);

  const handleSelection = (rowSelectionModel) => {
    if (rowSelectionModel.length === 1) {
      setSelectedUser(users.find((user) => user.id === rowSelectionModel[0]));
    } else {
      setSelectedUser(null);
    }
  };

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 2 }}>
      <Button onClick={handleOpen}>Add User</Button>
      <Button
        onClick={() => setConfirmDialogOpen(true)}
        disabled={!selectedUser}
      >
        Remove User
      </Button>
      {/* <Button onClick={() => setAdminDialogOpen(true)} disabled={!selectedUser}>
        {selectedUser?.is_admin ? 'Degrade from Admin' : 'Promote to Admin'}
      </Button> */}
      {/* Confirmation dialog for removing user */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the user "{selectedUser?.username}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={removeUser} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for adding a user */}
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Username</InputLabel>
            <OutlinedInput
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
            }
            label="Admin Status"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addUser} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={adminDialogOpen} onClose={() => setAdminDialogOpen(false)}>
        <DialogTitle>
          {selectedUser?.is_admin ? "Degrade from Admin" : "Promote to Admin"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to{" "}
            {selectedUser?.is_admin ? "degrade" : "promote"} user "
            {selectedUser?.username}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAdminDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={toggleAdminStatus} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        rows={users.map((user) => ({ ...user, is_admin: user.is_admin }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onRowSelectionModelChange={(rowSelectionModel) =>
          handleSelection(rowSelectionModel)
        }
        selectionModel={selectedUser ? [selectedUser.id] : []}
        checkboxSelection
      />
    </Container>
  );
}
