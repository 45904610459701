import React, { useState, useCallback } from 'react';
import { 
  TextField, 
  Button, 
  Grid, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  CircularProgress,
  Alert,
  Chip,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import sendRequest from '../../utils/sendRequest';
import ClearIcon from '@mui/icons-material/Clear';

const AddProducts = ({ setLoading, handleSnackbar }) => {
  const [skus, setSkus] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState(null);
  const [processing, setProcessing] = useState(false);

  const processInput = useCallback((input) => {
    const newSkus = input.split(/[\s,\n]+/).filter(sku => sku.trim() !== '');
    
    setSkus(prevSkus => {
      const skusTemp = {};
      // First, add existing SKUs to skusTemp
      prevSkus.forEach(sku => {
        const baseSku = sku.split('/')[0];
        skusTemp[baseSku] = sku;
      });
      
      // Then, process new SKUs
      newSkus.forEach(sku => {
        const baseSku = sku.split('/')[0];
        if (!(baseSku in skusTemp)) {
          skusTemp[baseSku] = sku;
        }
      });
      
      return Object.values(skusTemp).sort((a, b) => a.localeCompare(b));
    });
  }, []);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    processInput(pastedText);
  }, [processInput]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddClick = () => {
    processInput(inputValue);
    setInputValue('');
  };

  const handleClearClick = () => {
    setInputValue('');
    setSkus([]);
  };

  const handleDelete = (skuToDelete) => {
    setSkus(prevSkus => prevSkus.filter(sku => sku !== skuToDelete));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setResults(null);

    try {
      const response = await sendRequest('process_skus', { skus }, null, 'POST');
      setResults(response);
      setSkus([]);
      handleSnackbar(response.message, 'info');
    } catch (error) {
      handleSnackbar('Error processing SKUs: ' + error.message, 'error');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
      <Grid item xs={12} md={8}>
        <Typography variant="h5" gutterBottom>Add Products</Typography>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                fullWidth
                label="Enter or paste SKUs (comma, space, or newline-separated)"
                value={inputValue}
                onChange={handleInputChange}
                onPaste={handlePaste}
                margin="normal"
                variant="outlined"
                disabled={processing}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"

                size="small"
                onClick={handleAddClick}
                disabled={!inputValue.trim() || processing}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Grid>
            <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleClearClick}
              disabled={processing}
              startIcon={<ClearIcon />}
            >
              Clear
            </Button>
          </Grid>
          </Grid>
          

          <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
            {skus.map((sku, index) => (
              <Grid item key={index}>
                <Chip
                  label={sku}
                  onDelete={() => handleDelete(sku)}
                  deleteIcon={<CloseIcon />}
                  variant = {"outlined"}
                  size="small"
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1, mb: 2 }}>
            <Typography variant="body2" color="textSecondary">
              Total SKUs: {skus.length}/20
            </Typography>
          </Box>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            disabled={processing || skus.length === 0}
            sx={{ mt: 2 }}
          >
            {processing ? <CircularProgress size={24} /> : 'Process SKUs'}
          </Button>
        </form>
      </Grid>
      {results && (
        <Grid item xs={12} md={8}>
          <Alert severity="info" sx={{ mb: 2 }}>{results.message}</Alert>
          <List>
            {results.results.map((result, index) => (
              <ListItem 
                key={index} 
                button
                component="a"
                href={result.status === 'success' ? `/add_details/${result.form_id}` : `https://lux.delta.sellercloud.com/catalog/catalog-details.aspx?ID=${result.sku}`}
                target={result.status === 'success' ? "_blank" : "_blank"}
                rel={result.status === 'success' ? "noopener noreferrer" : "noopener noreferrer"}
              >
                <ListItemText
                  primary={`${result.sku}`+ (result.status === 'success' ? '' : ' - Click to open sellercloud and check if product has valid sku and images.' )}
                  sx={{ 
                    color: result.status === 'success' ? 'success.main' : 'error.main',
                    textDecoration: result.status === 'success' ? 'underline' : 'none'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default AddProducts;