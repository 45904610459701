import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Autocomplete,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { toTitleCase } from "../../utils/stringFormatter";
import {
  Add as AddIcon,
} from "@mui/icons-material";
import sendRequest from "../../utils/sendRequest";
import FieldAutocompleteOption from '../common/FieldAutocompleteOption';

const GridBreak = styled("div")(({ theme }) => ({ width: "100%" }));

function InputOptionsEditor({ setLoading, handleSnackbar }) {
  const [fields, setFields] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  useEffect(() => {
    getFieldsAndProductTypes();
  }, []);

  async function getFieldsAndProductTypes() {
    try {
      setLoading(true);
      const response = await sendRequest("get_field_product_type_list", null, null, "GET");
      setFields(response.fields);
      setProductTypes([{ name: "Default" }, ...response.product_types]);
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function getFieldInputOptions() {
    if (!selectedField || !selectedProductType) return;

    try {
      setLoading(true);
      const response = await sendRequest(
        "get_input_options",
        null,
        { field_id: selectedField.id, product_type: selectedProductType.name === "Default" ? "All" : selectedProductType.name },
        "GET"
      );
      setSelectOptions(response.input_options);
      setDefaultValue(response.default_value || "");
      setOptionsLoaded(true);
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function saveFieldInputOptions() {
    if (!selectedField || !selectedProductType) return;

    try {
      setLoading(true);
      const response = await sendRequest(
        "update_input_options",
        {
          field_id: selectedField.id,
          product_type: selectedProductType.name === "Default" ? "All" : selectedProductType.name,
          input_options: selectOptions,
          default_value: defaultValue
        },
        null,
        "POST"
      );
      handleSnackbar(response.message, "success");
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  const handleAddOption = () => {
    if (newOption && !selectOptions.includes(newOption)) {
      setSelectOptions([...selectOptions, newOption]);
      setNewOption("");
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    setSelectOptions(selectOptions.filter(option => option !== optionToRemove));
    if (defaultValue === optionToRemove) {
      setDefaultValue(""); // Clear default value if the removed option was the default
    }
  };

  const handleDefaultValueChange = (event, newValue) => {
    setDefaultValue(newValue);
  };

  return (
    <Grid
      container
      md={12}
      xs={8}
      alignItems={"center"}
      alignContent={"center"}
      justifyContent={"center"}
      justifyItems={"center"}
      paddingY={10}
      spacing={2}
    >
      <Grid item xs={8} md={6}>
        <Autocomplete
          options={fields}
          value={selectedField}
          onChange={(event, newValue) => {
            setSelectedField(newValue);
            setOptionsLoaded(false);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Field" variant="outlined"  />
          )}
          getOptionLabel={(option) => option.input_export_type_id ? 
            ((toTitleCase(option.input_export_type_id) + " - " + option.input_name) || "") : ""}
          renderOption={(props, option) => (
            <FieldAutocompleteOption key={option.id} props={props} option={option} />
          )}
        />
      </Grid>
      <GridBreak />
      <Grid item xs={8} md={6}>
        <Autocomplete
          options={productTypes}
          getOptionLabel={(option) => option.name}
          value={selectedProductType}
          onChange={(event, newValue) => {
            setSelectedProductType(newValue);
            setOptionsLoaded(false);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Product Type" variant="outlined" />
          )}
        />
      </Grid>
      <GridBreak />
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={getFieldInputOptions}
          disabled={!selectedField || !selectedProductType}
        >
          Get Options
        </Button>
      </Grid>

      {optionsLoaded && (
        <>
          <GridBreak />
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Add New Option"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddOption} edge="end">
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddOption();
                }
              }}
            />
          </Grid>

          <Grid item xs={8}>
            {selectOptions.map((option) => (
              <Chip
                key={option}
                label={option}
                onDelete={() => handleRemoveOption(option)}
                size="small"
                color="primary"
                variant="outlined"
                sx={{ margin: 0.5 }}
              />
            ))}
          </Grid>

          <GridBreak />

          <Grid item md={6} xs={8}>
            <Autocomplete
              options={selectOptions}
              value={defaultValue}
              onChange={handleDefaultValueChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Default Value"
                />
              )}
              freeSolo
            />
          </Grid>
          <GridBreak />
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={saveFieldInputOptions}
            >
              Save Options
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default InputOptionsEditor;