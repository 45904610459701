import React, { useState } from 'react';
import { Box, IconButton, Grid } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const ImageGallery = ({ images, maxItems = 5 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };


  if (!images || images.length === 0) {
    return null;
  }

  const thumbnailRows = [];
  for (let i = 0; i < images.length; i += maxItems) {
    thumbnailRows.push(images.slice(i, i + maxItems));
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ position: 'relative', width: '100%', marginBottom: 2 }}>
        <img
          src={images[currentIndex]}
          alt={`Product Image ${currentIndex + 1}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
        <IconButton
          onClick={handlePrev}
          size="small"
          sx={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.8)' },
          }}
        >
          <ArrowBack size="small"/>
        </IconButton>
        <IconButton
          onClick={handleNext}
          size='small'
          sx={{
            position: 'absolute',
            right: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.8)' },
          }}
        >
          <ArrowForward size="small"/>
        </IconButton>
      </Box>
      {thumbnailRows.map((row, rowIndex) => (
        <Grid container spacing={1} key={rowIndex} sx={{ marginBottom: 1 }}>
          {row.map((image, index) => (
            <Grid item xs={12 / maxItems} key={index + rowIndex * maxItems}>
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '100%',
                  position: 'relative',
                  cursor: 'pointer',
                  border: index + rowIndex * maxItems === currentIndex ? '2.5px solid #1976d2' : '2px solid transparent',
                }}
                onClick={() => handleThumbnailClick(index + rowIndex * maxItems)}
              >
                <img
                  src={image}
                  alt={`Thumbnail ${index + rowIndex * maxItems + 1}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );
};

export default ImageGallery;