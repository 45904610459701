import { useState, useMemo, useCallback, useEffect } from "react";
import {
  CssBaseline,
  LinearProgress,
  Fab,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { setUpInterceptors } from "./utils/sendRequest";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useSnackbar, SnackbarProvider } from "notistack";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SearchPage from "./components/pages/SearchPage";
import sendRequest from "./utils/sendRequest";
import DynamicForm from "./components/pages/DynamicForm";
import Login from "./components/pages/Login";
import CustomSnackbar from "./utils/CustomSnackbar";
import TemplateEditor from "./components/pages/TemplateEditor";
import FieldEditor from "./components/pages/FieldEditor";
import ViewSubmissions from "./components/pages/ViewSubmissions";
import InputOptionsEditor from "./components/pages/InputOptionsEditor";
import ParentFieldsEditor from "./components/pages/ParentFieldsEditor";
import AddSellercloudFields from "./components/pages/AddSellercloudFields";
import AddProducts from "./components/pages/AddProducts";
import { useHistory } from "react-router-dom";
import UserManagement from "./components/pages/UserManagement";
import { Link } from "react-router-dom";

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [toggleFab, setToggleFab] = useState(false);
  const [menuItems, setMenuItems] = useState([
    { name: "Add Info", link: "/" },
    { name: "Add Products", link: "/add_products" },
    { name: "Manage Templates", link: "/manage_templates" },
    { name: "Manage Input Options", link: "/manage_input_options" },
    { name: "Edit Field", link: "/edit_field" },
    { name: "Edit Parent Fields", link: "/manage_parents" },
    { name: "Add Sellercloud Fields", link: "/add_sellercloud_fields" },
    { name: "View Submissions", link: "/view_submissions" },
    { name: "User Management", link: "/user_management" },

  ]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [productTypes, setProductTypes] = useState([]);
  const [currentFormId, setCurrentFormId] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: prefersDarkMode ? '#90CAF9' : '#42A5F5',
            light: prefersDarkMode ? '#BBDEFB' : '#90CAF9',
            dark: prefersDarkMode ? '#64B5F6' : '#1E88E5',
            contrastText: prefersDarkMode ? '#000' : '#fff',
          },
          secondary: {
            main: prefersDarkMode ? '#80DEEA' : '#26C6DA',
            light: prefersDarkMode ? '#B2EBF2' : '#80DEEA',
            dark: prefersDarkMode ? '#4DD0E1' : '#00ACC1',
            contrastText: prefersDarkMode ? '#000' : '#000',
          },
          background: {
            default: prefersDarkMode ? '#303030' : '#F5F5F5',
            paper: prefersDarkMode ? '#424242' : '#FFFFFF',
          },
          text: {
            primary: prefersDarkMode ? '#E0E0E0' : '#212121',
            secondary: prefersDarkMode ? '#BDBDBD' : '#757575',
          },
          error: {
            main: prefersDarkMode ? '#EF9A9A' : '#F44336',
          },
          warning: {
            main: prefersDarkMode ? '#FFCC80' : '#FFA726',
          },
          info: {
            main: prefersDarkMode ? '#90CAF9' : '#29B6F6',
          },
          success: {
            main: prefersDarkMode ? '#A5D6A7' : '#66BB6A',
          },
        },
        // palette: {
        //   mode: prefersDarkMode ? "dark" : "light",
        //   background: {
        //     default: prefersDarkMode ? "#303030" : "#fff",
        //     paper: prefersDarkMode ? "#424242" : "#f5f5f5",
        //   },
        // },
        components: {
          MuiButton: {
            defaultProps: {
              size: "small",
            },
          },
          MuiButtonGroup: {
            defaultProps: {
              size: "small",
            },
          },
          MuiCheckbox: {
            defaultProps: {
              size: "small",
            },
          },
          MuiFab: {
            defaultProps: {
              size: "small",
            },
          },
          MuiFormControl: {
            defaultProps: {
              size: "small",
              // margin: "none",
            },
          },
          MuiFormHelperText: {
            defaultProps: {
              margin: "dense",
            },
          },
          MuiIconButton: {
            defaultProps: {
              size: "small",
            },
          },
          MuiRadio: {
            defaultProps: {
              size: "small",
            },
          },
          MuiSwitch: {
            defaultProps: {
              size: "small",
            },
          },
          MuiTextField: {
            defaultProps: {
              size: "small",
              //make it outlined
              variant: "outlined",
              // multiline: true,
            },
          },
        },
      }),
    [prefersDarkMode]
  );
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));


  useEffect(() => {
    setUpInterceptors(enqueueSnackbar, setLoading, history, setAuthenticated);

  }, [])

  const handleSnackbar = useCallback((message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);
  function isLoggedIn() {
    return true;
  }
  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ position: "fixed", width: "100%", zIndex: 9999 }}>
          {loading && <LinearProgress />}
        </div>

        <Router>
          <Switch>
            {isLoggedIn() ? (
              <>
                <Fab
                  style={{
                    position: !desktop ? "fixed" : "fixed",
                    bottom: !desktop ? 5 : "none",
                    right: !desktop ? 5 : "none",
                    top: desktop ? 10 : "auto",
                    left: desktop ? 10 : "auto",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    boxShadow: "none",
                  }}
                  size="medium"
                  aria-label="menu"
                  onClick={() => setToggleDrawer(true)}
                >
                  <MenuIcon
                    color="primary"
                    fontSize={desktop ? "large" : "medium"}
                  />
                </Fab>
                <Drawer
                  anchor={desktop ? "left" : "bottom"}
                  open={toggleDrawer}
                  onClose={() => setToggleDrawer(false)}
                >
                  <List>
                    {menuItems.map((item, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          component={Link}
                          to={item.link}
                          onClick={() => {
                            setToggleDrawer(false);
                          }}
                        >
                          <ListItemText primary={item.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                    {
                      <ListItem key={"logout"} disablePadding>
                        <ListItemButton
                          onClick={() => {
                            localStorage.removeItem("token");
                            setToggleDrawer(false);
                          }}
                        >
                          <ListItemText primary={"Logout"} />
                        </ListItemButton>
                      </ListItem>
                    }
                  </List>
                </Drawer>

                <Route exact path="/">
                  <SearchPage
                    setLoading={setLoading}
                    handleSnackbar={handleSnackbar}
                    sendRequest={sendRequest}
                    productTypes={productTypes}
                    setProductTypes={setProductTypes}
                    currentProduct={currentProduct}
                    setCurrentProduct={setCurrentProduct}
                    selectedProductType={selectedProductType}
                    setSelectedProductType={setSelectedProductType}
                    setCurrentFormId={setCurrentFormId}
                  />
                </Route>
                <Route path="/add_details/:urlFormId">
                  <DynamicForm
                    handleSnackbar={handleSnackbar}
                    currentProduct={currentProduct}
                    setCurrentProduct={setCurrentProduct}
                    selectedProductType={selectedProductType}
                    setSelectedProductType={setSelectedProductType}
                    setLoading={setLoading}
                    currentFormId={currentFormId}
                    setCurrentFormId={setCurrentFormId}
                  />
                </Route>
                <Route exact path="/add_details">
                  {currentProduct && selectedProductType ? (
                    <DynamicForm
                      handleSnackbar={handleSnackbar}
                      currentProduct={currentProduct}
                      setCurrentProduct={setCurrentProduct}
                      selectedProductType={selectedProductType}
                      setSelectedProductType={setSelectedProductType}
                      setLoading={setLoading}
                      currentFormId={currentFormId}
                      setCurrentFormId={setCurrentFormId}
                    />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>
                <Route exact path="/manage_templates">
                  <TemplateEditor
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>
                <Route exact path="/manage_input_options">
                  <InputOptionsEditor
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>
                <Route exact path="/add_products">
                  <AddProducts
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>
                <Route path="/edit_field/:id?">
                  <FieldEditor
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>
                <Route exact path="/view_submissions">
                  <ViewSubmissions
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                    setCurrentFormId={setCurrentFormId}
                    setCurrentProduct={setCurrentProduct}
                    setSelectedProductType={setSelectedProductType}
                  />
                </Route>
                <Route exact path="/manage_parents">
                  <ParentFieldsEditor
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                    setCurrentFormId={setCurrentFormId}
                    setCurrentProduct={setCurrentProduct}
                    setSelectedProductType={setSelectedProductType}
                  />
                </Route>

                <Route exact path="/add_sellercloud_fields">

                  <AddSellercloudFields
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>


                <Route exact path="/user_management">
                  <UserManagement
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>
                <Route exact path="/login">
                  <Login
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>
              </>
            ) : (
              <>
                <Route exact path="/login">
                  <Login
                    handleSnackbar={handleSnackbar}
                    setLoading={setLoading}
                  />
                </Route>
                <Redirect to="/login" />
              </>
            )}
          </Switch>
        </Router>
        <CustomSnackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
