import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? "rgba(255,255,255,0.85)"
      : "rgba(0,0,0,.85)",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.background.paper,
  },
  "& .MuiDataGrid-iconSeparator": { display: "none" },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "dark" ? "#424242" : "#f0f0f0"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "dark" ? "#424242" : "#f0f0f0"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)",
  },
  "& .MuiPaginationItem-root": { borderRadius: 0 },
}));

export default StyledDataGrid;
