import React from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const CustomSnackbar = ({ open, onClose, message, severity }) => {
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose} anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
      }}>
      <Alert onClose={(e)=>{onClose(e)}} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;