import {
  useEffect,
  React,
  useState,
  createRef,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTheme } from '@mui/material/styles';
import Form from "@rjsf/mui";
import { styled } from "@mui/material/styles";
import ImageGallery  from "../common/ImageGallery";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import {
  getSubmitButtonOptions,
  WidgetProps,
  RegistryWidgetsType,
  FieldProps
} from "@rjsf/utils";
import { ThemeProps, withTheme } from "@rjsf/core";
import MuiForm, { Theme } from "@rjsf/mui";
import {
  Container,
  ListItemText,
  Button,
  ListItem,
  Checkbox,
  Grid,
  Autocomplete,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  DialogContentText,
  Link,
  FormLabel,
  Chip,
  IconButton,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import MUIRichTextEditor from "mui-rte";

import validator from "@rjsf/validator-ajv8";
import sendRequest from "../../utils/sendRequest";
import { useHistory, useParams } from "react-router-dom";
import {
  useGridApiRef,
  useGridApiContext,
  GridCellModes,
} from "@mui/x-data-grid";
import CustomObjectFieldTemplate from "../common/CustomObjectFieldTemplate";
import StyledDataGrid from "../common/StyledDataGrid";

const GridBreak = styled("div")(({ theme }) => ({ width: "100%" }));
const myTheme = {
  ...Theme,
  templates: {
    ...Theme.templates,
    ObjectFieldTemplate: CustomObjectFieldTemplate,
  },
};

const ThemedForm = withTheme(myTheme);
function prepareHTML(htmlString) {
  const contentHTML = convertFromHTML(htmlString);
  const state = ContentState.createFromBlockArray(
    contentHTML.contentBlocks,
    contentHTML.entityMap
  );
  const content = JSON.stringify(convertToRaw(state));

  return content;
}
function createHyperlink(message) {
  const regex = /<<([^|]+)\|\|([^>]+)>>/g;
  return message.replace(regex, (match, text, url) => `<a href="${url}" target="_blank">${text}</a>`);
}
const MyCustomWidget = (props) => {
  const [initialValue, setInitialValue] = useState("");
  useEffect(() => {
    if (!initialValue) {
      setInitialValue(prepareHTML(props.value));
    }
  }, [props.value]);
  const handleChange = (state) => {
    const html = stateToHTML(state.getCurrentContent());
    props.onChange(html);
  };
  if (!initialValue) {
    return null;
  }
  return (
    <>
      <FormLabel
        style={{
          marginLeft: "0.71em",
          marginTop: "-0.71em",
          paddingLeft: "0.44em",
          zIndex: 2,
          width: "6.5em",
          backgroundColor: "#383838",
          position: "absolute",
          fontSize: "0.75em",
        }}
      >
        {props.label}
      </FormLabel>
      <Box
        sx={{
          borderRadius: 1,
          border: 1,
          paddingLeft: 2,
          borderColor: "text.disabled",
        }}
      >
        <MUIRichTextEditor
          type="text"
          className="custom"
          defaultValue={initialValue}
          required={props.required}
          onChange={handleChange}
        />
      </Box>
    </>
  );
};


const WarningTextField = styled(TextField)(({ theme, haswarning }) => ({
  ...(haswarning === 'true' && {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.warning.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.warning.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.warning.main,
      },
    },
  }),
}));

const FreeTextSelectWidget = ({ 
  id, 
  schema, 
  uiSchema,
  value, 
  required, 
  disabled, 
  readonly, 
  onChange, 
  onBlur, 
  onFocus,
  label
}) => {
  const [inputValue, setInputValue] = useState('');
  const [hasWarning, setHasWarning] = useState(false);
  const isMultiSelect = schema.type === "array";
    
  let options = [];
  if (isMultiSelect && schema.items && schema.items.anyOf) {
    options = schema.items.anyOf[0].enum || [];
  } else if (schema.anyOf) {
    options = schema.anyOf[0].enum || [];
  }

  useEffect(() => {
    if (options.length > 0) {
      if (isMultiSelect) {
        setHasWarning(Array.isArray(value) && value.some(v => !options.includes(v)));
      } else {
        setHasWarning(value !== undefined && value !== null && !options.includes(value));
      }
    } else {
      setHasWarning(false);
    }
  }, [value, options, isMultiSelect]);

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleAddChip = () => {
    if (inputValue && inputValue.trim() !== '') {
      const newValue = isMultiSelect ? [...(value || []), inputValue] : inputValue;
      onChange(newValue);
      setInputValue('');
    }
  };

  return (
    <Autocomplete
      multiple={isMultiSelect}
      id={id}
      options={options}
      freeSolo
      disableClearable={true}
      value={isMultiSelect ? (value || []) : (value || '')}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      disabled={disabled || readonly}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <WarningTextField
          {...params}
          label={label || schema.title}
          required={required}
          variant="outlined"
          fullWidth
          haswarning={hasWarning.toString()}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {isMultiSelect && (
                  <IconButton onClick={handleAddChip} edge="end">
                    <AddIcon />
                  </IconButton>
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
};


const FreeTextSelectField = ({ id, schema, uiSchema, formData, onChange, onBlur, onFocus, ...props }) => {
  return (
    <FreeTextSelectWidget
      id={id}
      schema={schema}
      uiSchema={uiSchema}
      value={formData}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      {...props}
    />
  );
};
const widgets = {
  myCustomWidget: MyCustomWidget,
};
const fields = {
  freeTextSelect: FreeTextSelectField,
};

function SelectEditInputCell(props) {
  const theme = useTheme();
  const { id, value, field, setFormData, options, type, isSelectFreeText } = props;
  const apiRef = useGridApiContext();
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = async (event, newValue) => {
    const updatedValue = newValue !== null ? newValue : inputValue;
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: type === "number" ? Number(updatedValue) : updatedValue,
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      formDataSKUSpecific: prevFormData.formDataSKUSpecific.map((row) =>
        row.id === id
          ? { ...row, [field]: type === "number" ? Number(updatedValue) : updatedValue }
          : row
      ),
    }));

    if (event.type === 'blur' || event.key === 'Enter') {
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  const inputProps = {
    style: {
      width: '100%',
      height: '100%',
      padding: `0 ${theme.spacing(1)}`,
      border: 'none',
      outline: 'none',
      background: 'transparent',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      display: 'flex',
      alignItems: 'center',
    },
  };

  if (options) {
    return (
      <Autocomplete
        value={value}
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        freeSolo={isSelectFreeText}
        disableClearable
        forcePopupIcon={false}
        sx={{
          width: '100%',
          //height: '100%',
          '& .MuiInputBase-root': {
            paddingY: '0 !important',
          },
          '& .MuiAutocomplete-inputRoot': {
            paddingY: '0 !important',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              disableUnderline: true,
            }}
            onBlur={(e) => handleChange(e, e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleChange(e, e.target.value);
              }
            }}
          />
        )}
      />
    );
  } else {
    return (
      <TextField
        type={type}
        value={value}
        variant="standard"
        InputProps={{
          ...inputProps,
          disableUnderline: true,
        }}
        inputRef={inputRef}
        onChange={(e) => handleChange(e, e.target.value)}
        onBlur={(e) => handleChange(e, e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleChange(e, e.target.value);
          }
        }}
      />
    );
  }
}

const transformSchemaToColumns = (formData, setFormData) => {
  const columns = [];
  const schema = formData?.schemaSKUSpecific?.properties || {};

  for (const [key, val] of Object.entries(schema)) {
    let column = {
      field: key,
      headerName: val.title,
      width: 200,
      editable: true,
      renderEditCell: (params) => (
        <SelectEditInputCell
          {...params}
          setFormData={setFormData}
          options={val.enum || (val.anyOf && val.anyOf[0].enum) || null}
          type={val.type}
          isSelectFreeText={val.anyOf !== undefined}
        />
      ),
    };

    columns.push(column);
  }

  return columns;
};
function DynamicForm({
  handleSnackbar,
  selectedProductType,
  setSelectedProductType,
  currentProduct,
  setCurrentProduct,
  setLoading,
  currentFormId,
  setCurrentFormId,
}) {
  const history = useHistory();
  const formRef = createRef();

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [errorModalTitle, setErrorModalTitle] = useState("Error");
  const [formData, setFormData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [saveModalMessage, setSaveModalMessage] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [saveModalConfirm, setSaveModalConfirm] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [dbProductIds, setDbProductIds] = useState([]);
  const [currentStep, setCurrentStep] = useState("form");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState(
    "Are you sure you want to delete this form?"
  );
  const [deleteModalConfirm, setDeleteModalConfirm] = useState(false);
  const [cellModesModel, setCellModesModel] = useState({});
  const [productTypes, setProductTypes] = useState([]);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [newProductType, setNewProductType] = useState("");
  const [productImages, setProductImages] = useState([]);

  const apiRef = useGridApiRef();
  const { urlFormId } = useParams();

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Product ID",
      width: 150,
      renderCell: (params) => (
        <Link
          href={`https://lux.delta.sellercloud.com/catalog/catalog-details.aspx?ID=${encodeURIComponent(
            params.value
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </Link>
      ),
    },
    ...transformSchemaToColumns(formData, setFormData),
  ];

  async function updateProductIds(productId) {
    setLoading(true);
    var response = await sendRequest(
      "child_product_list_quick",
      productId && productId !== null && productId !== undefined
        ? { product_id: productId }
        : {
            product_id: currentProduct.ID,
          },
      null,
      "POST"
    );
    
    setDbProductIds(response);
    setLoading(false);
  }

  async function generateForm(urlFormId) {
    try {
      setLoading(true);
      var response = await sendRequest(
        "generate_form",
        urlFormId && urlFormId !== null && urlFormId !== undefined
          ? { form_id: urlFormId }
          : {
              product_id: currentProduct.ID,
              product_type: selectedProductType,
              form_id: currentFormId,
            },
        null,
        "POST"
      );
      if (response.form_id && response?.is_new !== true && response.selected_product_ids.length) {
        await getProductById(response.product_id);
        await updateProductIds(response.product_id);
        setProductIds(response.selected_product_ids);
        setFormData(response.form_data);
        setSelectedProductType(response.product_type);

        setCurrentStep("sku_specific");

        Promise.all([
          handleSnackbar("Product form loaded", "success"),
          setLoading(false),
        ]);
      } else {
        setFormData(response["form_data"]);
        if (response?.product_id) {
          await getProductById(response.product_id);
          await updateProductIds(response.product_id);
          setSelectedProductType(response.product_type);
        } else {
          await updateProductIds();
        }
        Promise.all([
          handleSnackbar("Product form loaded", "success"),
          setLoading(false),
        ]);
      }
    } catch (error) {
      history.push("/");
      Promise.all([handleSnackbar(error.message, "error"), setLoading(false)]);
    }
  }

  async function getProductById(productId) {
    try {
      setLoading(true);
      var response = await sendRequest(
        "get_product_by_id",
        null,
        { product_id: productId },
        "GET"
      );
      setCurrentProduct(response);
      setProductImages(response.images || []);
      setLoading(false);
    } catch (error) {
      Promise.all([handleSnackbar(error.message, "error"), setLoading(false)]);
    }
  }

  useEffect(() => {
    getProductTypes();
    if (urlFormId) {
      setCurrentFormId(urlFormId);
    }
    (async () => {
      generateForm(urlFormId);
    })();
  }, []);

  function handleCheckboxChange(productId, isChecked) {
    if (isChecked) {
      setProductIds((prevIds) => [...prevIds, productId]);
    } else {
      setProductIds((prevIds) => prevIds.filter((id) => id !== productId));
    }
  }

  const addCustomObjectFieldTemplate = (uiSchema) => {
    if (!uiSchema["ui:ObjectFieldTemplate"]) {
      uiSchema["ui:ObjectFieldTemplate"] = CustomObjectFieldTemplate;
    }
    return uiSchema;
  };

  async function submitForm() {
    try {
      console.log("submitForm",formData);
      setLoading(true);
      var response = await sendRequest(
        "update_product_info",
        {
          product_id: currentProduct.ID,
          selected_product_ids: productIds,
          product_type: selectedProductType,
          form_data: formData,
          form_id: currentFormId,
        },
        null,
        "POST"
      );
      if (response.status && response.status === "success") {
        Promise.all([
          setLoading(false),
          handleSnackbar("Product Info updated", "success"),
        ]);

        setCurrentProduct("");
        setSelectedProductType("");
        setCurrentFormId("");
        history.length > 2
          ? history.goBack()
          : history.push("/view_submissions");
      } else {
        const updatedForm = response?.detail?.form;

        if (updatedForm !== null && updatedForm !== undefined) {
          setFormData({
            ...formData,
            schema: response.detail.form.schema,
            uiSchema: addCustomObjectFieldTemplate(
              response.detail.form.uiSchema
            ),
          });
          setCurrentStep("form");
          setErrorModalMessage(response.detail.message);
          setErrorModalOpen(true);
          setLoading(false);
        } else {
          handleSnackbar(response.detail.message, "error");
          setLoading(false);
        }
      }
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function saveForm() {
    try {
      setLoading(true);
      var response = await sendRequest(
        "save_form",
        {
          product_id: currentProduct.ID,
          selected_product_ids: productIds,
          product_type: selectedProductType,
          form_data: formData,
          form_id: currentFormId,
        },
        null,
        "POST"
      );
      if (response.status && response.status === "success") {
        Promise.all([
          setLoading(false),
          handleSnackbar("Product Info Saved", "success"),

          setCurrentStep("sku_specific"),
          setCurrentFormId(response.form_id),
        ]);
      } else {
        setFormData({
          ...formData,
          schema: response.detail.form.schema,
          uiSchema: addCustomObjectFieldTemplate(response.detail.form.uiSchema),
        });
        setCurrentStep("form");
        setErrorModalMessage(response.detail.message);
        setErrorModalOpen(true);
        setLoading(false);
      }
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function deleteForm() {
    try {
      setLoading(true);
      var response = await sendRequest(
        "delete_submission",
        null,
        { form_id: currentFormId },
        "DELETE"
      );
      setLoading(false);
      handleSnackbar(response.message, "success");
      setCurrentFormId("");
      setCurrentStep("form");
      history.length > 2 ? history.goBack() : history.push("/view_submissions");
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function updateFormStatus(status) {
    try {
      setLoading(true);
      var response = await sendRequest(
        "update_form_status",
        null,
        { form_id: currentFormId, status: status },
        "PUT"
      );
      setLoading(false);
      handleSnackbar(response.message, "success");
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function confirmDetails(data, e) {
    const submission_type = e.nativeEvent.submitter.innerText;

    const is_valid = formRef.current.validateForm();
    if (is_valid) {
      if (submission_type === "SUBMIT") {
        try {
          setLoading(true);
          var response = await sendRequest(
            "get_child_product_list",
            {
              product_id: currentProduct.ID,
              product_type: selectedProductType,
              form_data: formData.formData,
            },
            null,
            "POST"
          );
          if (response.status && response.status === "success") {
            Promise.all([setLoading(false)]);
            if (!currentFormId.length) {
              setProductIds(response["data"]);
            }
            setModalMessage(response["data"]);
            setModalOpen(true);
          } else {
            setFormData({
              ...formData,
              schema: response.detail.form.schema,
              uiSchema: addCustomObjectFieldTemplate(
                response.detail.form.uiSchema
              ),
            });
            setErrorModalMessage(response.detail.message);
            setErrorModalOpen(true);
            setLoading(false);
          }
        } catch (error) {
          handleSnackbar(error.message, "error");
          setLoading(false);
        }
      } else if (submission_type === "NEXT") {
        try {
          setLoading(true);
          response = await sendRequest(
            "get_child_product_list",
            {
              product_id: currentProduct.ID,
              product_type: selectedProductType,
              form_data: formData.formData,
            },
            null,
            "POST"
          );
          if (response.status && response.status === "success") {
            Promise.all([setLoading(false)]);
            if (!currentFormId.length) {
              setProductIds(response["data"]);
            }
            if (!productIds.length) {
              setProductIds(response["data"]);
            }
            if (!formData.formDataSKUSpecific.length) {
              console.log("no form data")
              const newRows = response["data"].map((productId) => ({
                id: productId,
              }));
              var dbProductIds = response["data"];
              setDbProductIds(dbProductIds);
              setFormData({
                ...formData,
                formDataSKUSpecific: newRows,
              });
            }
            saveForm();
          } else {
            setFormData({
              ...formData,
              schema: response.detail.form.schema,
              uiSchema: addCustomObjectFieldTemplate(
                response.detail.form.uiSchema
              ),
            });
            setErrorModalMessage(response.detail.message);
            setErrorModalOpen(true);
            setLoading(false);
          }
        } catch (error) {
          handleSnackbar(error.message, "error");
          setLoading(false);
        }
      }
    }
  }

  async function getProductTypes() {
    try {
      setLoading(true);
      var response = await sendRequest("get_product_types", null, null, "GET");
      setProductTypes(response);
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  const handleCategoryChange = () => {
    setOpenCategoryModal(true);
  };

  const handleConfirmCategoryChange = async () => {
    setOpenCategoryModal(false);
    try {
      setLoading(true);
      const response = await sendRequest(
        "update_product_category",
        {
          form_id: currentFormId,
          new_product_type: newProductType,
        },
        null,
        "POST"
      );
      setSelectedProductType(newProductType);
      setFormData(response.form_data);
      handleSnackbar("Product category updated successfully", "success");
    } catch (error) {
      handleSnackbar(error.message, "error");
    } finally {
      setLoading(false);
      setOpenCategoryModal(false);
    }
  };

  function SubmitButton(props) {
    const { uiSchema } = props;
    const { norender } = getSubmitButtonOptions(uiSchema);
    if (norender) {
      return null;
    }
    return (
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: 1 }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <Button size="medium" variant="outlined" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    formData && (
      <>
        <Container
          maxWidth={currentStep === "lg"}
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div
                style={{
                  flex: "0 0 auto",
                  marginLeft: "10%",
                  position: "sticky",
                  top: "25%",
                  transform: "translateY(-50%);",
                  width: "85%",
                }}
              >
                 <ImageGallery images={productImages} maxItems={4}/>
              </div>
            </Grid>
            <Grid item xs={9}>
            <Grid container spacing={2} alignItems="center" justifyContent="center"  sx={{paddingBottom:2}}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            value={selectedProductType}
            onChange={(event, newValue) => setNewProductType(newValue)}
            options={productTypes}
            renderInput={(params) => <TextField {...params} label="Product Category" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCategoryChange}
            disabled={!newProductType || newProductType === selectedProductType}
          >
            Change Category
          </Button>
        </Grid>
      </Grid>
              {currentStep === "form" && (
                <ThemedForm
                  schema={formData.schema}
                  uiSchema={formData.uiSchema}
                  formData={formData.formData}
                  widgets={widgets}
                  fields={fields}
                  onChange={(e) => {
                    setFormData({ ...formData, formData: e.formData });
                  }}
                  onSubmit={(data, e) => {
                    confirmDetails(data, e);
                  }}
                  validator={validator}
                  ref={formRef}
                  templates={{ ButtonTemplates: { SubmitButton } }}
                  noHtml5Validate={true}
                  liveValidate={true}
                ></ThemedForm>
              )}
              {currentStep === "sku_specific" &&
                (dbProductIds.length ? (
                  <>
                    <GridBreak />
                    {dbProductIds.map((productId) => (
                      <FormControlLabel
                        key={productId}
                        control={
                          <Checkbox
                            size="small"
                            value={productId}
                            checked={productIds.includes(productId)}
                            onChange={() => {
                              const newProductIds = productIds.includes(
                                productId
                              )
                                ? productIds.filter((id) => id !== productId)
                                : [...productIds, productId];
                              setProductIds(newProductIds);
                              
                              const newRows = productIds.includes(productId)
                                ? formData.formDataSKUSpecific.filter(
                                    (row) => row.id !== productId
                                  )
                                : [
                                    ...formData.formDataSKUSpecific,
                                    { id: productId },
                                  ];
                              setFormData({
                                ...formData,
                                formDataSKUSpecific: newRows,
                              });
                            }}
                          />
                        }
                        label={productId}
                      />
                    ))}
                    <StyledDataGrid
                      rows={formData.formDataSKUSpecific}
                      columns={columns}
                      disableSelectionOnClick
                      apiRef={apiRef}
                      cellModesModel={cellModesModel}
                      onCellModesModelChange={handleCellModesModelChange}
                      onCellClick={handleCellClick}
                    />
                    <GridBreak />
                    <Grid
                      container
                      spacing={2}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{ paddingTop: 2 }}
                    >
                      <GridBreak />
                      <Grid item>
                        <Button
                          size="medium"
                          variant="outlined"
                          onClick={() => setCurrentStep("form")}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="medium"
                          variant="outlined"
                          onClick={async () => {
                            await saveForm();
                            history.length > 2
                              ? history.goBack()
                              : history.push("/view_submissions");
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="medium"
                          variant="outlined"
                          onClick={() => {
                            setDeleteModalOpen(true);
                          }}
                        >
                          Delete
                        </Button>
                        <Dialog
                          open={deleteModalOpen}
                          onClose={(e) => {
                            setDeleteModalOpen(false);
                          }}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {deleteModalMessage}
                          </DialogTitle>
                          <DialogActions>
                            <Button
                              onClick={async () => {
                                setDeleteModalConfirm(true);
                                setDeleteModalOpen(false);
                                await deleteForm();
                              }}
                            >
                              Confirm
                            </Button>
                            <Button
                              onClick={(e) => {
                                Promise.all([
                                  setDeleteModalOpen(false),
                                  setDeleteModalConfirm(false),
                                ]);
                              }}
                            >
                              Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>

                      {formData.is_admin && (
                        <>
                          <Grid item>
                            <Button
                              size="medium"
                              variant="outlined"
                              onClick={async () => {
                                await updateFormStatus("review");
                                history.length > 2
                                  ? history.goBack()
                                  : history.push("/view_submissions");
                              }}
                            >
                              Review
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="medium"
                              variant="outlined"
                              onClick={() => setModalOpen(true)}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                ) : (
                  !setLoading && (
                    <Typography variant="h6">No products to update</Typography>
                  )
                ))}
            </Grid>
          </Grid>

          <Dialog
            open={modalOpen}
            onClose={(e) => {
              setModalOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"The following products will be updated"}
            </DialogTitle>
            <DialogContent>
              {productIds ? (
                <Grid
                  container
                  spacing={0}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  {productIds.map((productId) => {
                    return (
                      <ListItem dense={true} key={productId}>
                        <ListItemText>
                          <Link
                            href={`https://lux.delta.sellercloud.com/catalog/catalog-details.aspx?ID=${encodeURIComponent(
                              productId
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {productId}
                          </Link>
                        </ListItemText>
                      </ListItem>
                    );
                  })}{" "}
                </Grid>
              ) : (
                "No products to update"
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  Promise.all([setModalOpen(false), setModalConfirm(false)]);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  Promise.all([
                    setModalConfirm(true),
                    setModalOpen(false),
                    submitForm(),
                  ]);
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={saveModalOpen}
            onClose={(e) => {
              setSaveModalOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"The following products will be updated"}
            </DialogTitle>
            <DialogContent>
              {saveModalMessage ? (
                <Grid
                  container
                  spacing={0}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  {saveModalMessage.map((productId) => {
                    return (
                      <ListItem dense={true} key={productId}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxChange(productId, e.target.checked)
                          }
                          checked={productIds.includes(productId)}
                        />
                        <ListItemText>
                          <Link
                            href={`https://lux.delta.sellercloud.com/catalog/catalog-details.aspx?ID=${encodeURIComponent(
                              productId
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {productId}
                          </Link>
                        </ListItemText>
                      </ListItem>
                    );
                  })}{" "}
                </Grid>
              ) : (
                "No products to update"
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  Promise.all([
                    setSaveModalOpen(false),
                    setSaveModalConfirm(false),
                  ]);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  Promise.all([
                    setSaveModalConfirm(true),
                    setSaveModalOpen(false),
                    saveForm(),
                  ]);
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={errorModalOpen}
            onClose={(e) => setErrorModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{errorModalTitle}</DialogTitle>
            <DialogContent><div dangerouslySetInnerHTML={{ __html: createHyperlink(errorModalMessage) }} /></DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  Promise.all([setErrorModalOpen(false)]);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openCategoryModal} onClose={() => setOpenCategoryModal(false)}>
        <DialogTitle>Confirm Category Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the product category to {newProductType}? 
            This will update the form fields and may result in loss of some data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCategoryModal(false)}>Cancel</Button>
          <Button onClick={handleConfirmCategoryChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
        </Container>
      </>
    )
  );
}

export default DynamicForm;
