import { useEffect, useState } from "react";
import { Grid, Chip, Button, Autocomplete, TextField } from "@mui/material";
import sendRequest from "../../utils/sendRequest";
import { useHistory } from "react-router-dom";
import formatDate from "../../utils/formatDate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StyledDataGrid from "../common/StyledDataGrid";
import { snakeToTitle } from "../../utils/stringFormatter";

function ViewSubmissions({
  setLoading,
  handleSnackbar,
  setCurrentFormId,
  setCurrentProduct,
  setSelectedProductType,
}) {
  const initialFilters = JSON.parse(sessionStorage.getItem("pageFilters")) || {
    status: [],
    usernames: [],
    start_date: null,
    end_date: null,
    page: 0,
    page_size: 10,
  };
  const [pageFilters, setPageFilters] = useState(initialFilters);
  const [submissions, setSubmissions] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [submissionsLoading, setSubmissionsLoading] = useState(true);
  const [formStatusOptions, setFormStatusOptions] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const history = useHistory();
  const columnConfig = {
    id: { label: "ID", type: "string", hidden: true },
    status: { label: "Status", type: "string", format: "title" },
    modified_by: { label: "Modified By", type: "string" },
    modified_on: { label: "Modified On", type: "date", width: 200 },
    product_id: { label: "Product ID", type: "string", width: 200 },
    product_type: { label: "Product Type", type: "string", width: 200 },
    selected_product_ids: {
      label: "Selected Products",
      type: "list",
      width: 400,
    },
  };

  const handleRowClick = (submission) => {
    Promise.all([
      setCurrentFormId(submission.id),
      setSelectedProductType(submission.product_type),
      setCurrentProduct({ ID: submission.product_id }),
    ]);
    history.push(`/add_details/${submission.id}`);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("pageFilters", JSON.stringify(pageFilters));
    getSubmissions();
  }, [pageFilters]);

  async function getSubmissions() {
    try {
      setLoading(true);
      const params = { ...pageFilters };
      params.page = params.page + 1;
      var response = await sendRequest("get_submissions", params, null, "POST");

      setSubmissions(response.results);
      setTotalResults(response.total);
      setLoading(false);
      setSubmissionsLoading(false);
    } catch (error) {
      setSubmissionsLoading(false);
      console.log(error);
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  async function getInitialData() {
    try {
      setLoading(true);
      const userResponse = await sendRequest(
        "submissions_meta",
        null,
        null,
        "GET"
      );

      setUsernames(userResponse.users);
      setFormStatusOptions(userResponse.statuses);
      setLoading(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
      setLoading(false);
    }
  }

  const columns = Object.keys(columnConfig)
    .filter((key) => !columnConfig[key].hidden)
    .map((key) => ({
      field: key,
      headerName: columnConfig[key].label,
      width: columnConfig[key].width || 150,
      valueFormatter: (params) => {
        if (columnConfig[key].type === "date") {
          return formatDate(params.value);
        } else if (columnConfig[key].type === "list") {
          return params.value.join(", ");
        } else if (columnConfig[key]?.format === "title") {
          return snakeToTitle(params.value);
        }
        return params.value;
      },
    }));
  return (
    <Grid
      container
      spacing={2}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ paddingTop: 2 }}
    >
      <Grid
        container
        item
        spacing={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        sx={{ paddingTop: 2 }}
        md={10}
      >
        <Grid item xs={12} md={3}>
          <Autocomplete
            multiple
            size="small"
            id="usernameFilter"
            fullWidth={false}
            options={usernames}
            value={pageFilters.usernames}
            onChange={(event, newValue) => {
              setPageFilters((prev) => ({
                ...prev,
                usernames: newValue,
                page: 0,
              }));
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                variant="outlined"
                label="Users"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip size="small" label={option} {...getTagProps({ index })} />
              ))
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            multiple
            size="small"
            id="statusFilter"
            fullWidth={false}
            options={formStatusOptions}
            value={pageFilters.status}
            onChange={(event, newValue) => {
              setPageFilters((prev) => ({
                ...prev,
                status: newValue,
                page: 0,
              }));
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                variant="outlined"
                label="Status"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  label={snakeToTitle(option)}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, option) => (
              <li {...props}>{snakeToTitle(option)}</li>
            )}
          />
        </Grid>
        <Grid item xs={4} md={2.5}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              size="small"
              label="Start Date"
              value={pageFilters.start_date}
              onChange={(newValue) =>
                setPageFilters((prev) => ({
                  ...prev,
                  start_date: newValue,
                  page: 0,
                }))
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} md={2.5}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              size="small"
              label="End Date"
              value={pageFilters.end_date}
              onChange={(newValue) =>
                setPageFilters((prev) => ({
                  ...prev,
                  end_date: newValue,
                  page: 0,
                }))
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPageFilters((prev) => ({ ...prev, page: 0 }))}
            size="medium"
          >
            Apply
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} md={10}>
        <StyledDataGrid
          rows={submissions}
          density="compact"
          columns={columns}
          autoHeight
          disableSelectionOnClick
          onRowClick={(params) => {
            const submission = params.row;
            if (["draft", "review", "submitted"].includes(submission.status)) {
              handleRowClick(submission);
            }
          }}
          rowCount={totalResults}
          paginationMode="server"
          pageSize={pageFilters.page_size}
          pageSizeOptions={[5, 10, 25]}
          paginationModel={{
            page: pageFilters.page,
            pageSize: pageFilters.page_size,
          }}
          onPaginationModelChange={(model) => {
            setPageFilters((prev) => ({
              ...prev,
              page: model.pageSize !== prev.page_size ? 0 : model.page,
              page_size: model.pageSize,
            }));
          }}
          loading={submissionsLoading}
        />
      </Grid>
    </Grid>
  );
}

export default ViewSubmissions;
